import { plansorchestrator } from '@tabeo/platform-api-client'
import {
  Button,
  Checkbox,
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  FFCheckbox,
} from '@tabeo/scarf2'
import FormError from 'components/Form/FormError'
import { Field } from 'components/nnts/form/Field'
import { scrollToErrorDecorator } from 'components/nnts/form/scrollToError'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import ComparisonTable from './ComparisonTable'
import PaymentChangeNotification from './PaymentChangeNotification'
import StepIndicator from './StepIndicator'

interface ReviewStepProps {
  currentPlan?: plansorchestrator.ModelsPlan
  selectedPlan?: plansorchestrator.ModelsPlan
  subscriptionData: any
  selectedPlanId: string
  patientName?: string
  onSubmit: (values: any) => Promise<any>
  onBack: () => void
}

const ReviewStep = ({
  currentPlan,
  selectedPlan,
  subscriptionData,
  selectedPlanId,
  patientName,
  onSubmit,
  onBack,
}: ReviewStepProps) => {
  const { t } = useTranslation()

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ planId: selectedPlanId, consent: false }}
      decorators={[scrollToErrorDecorator]}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className="contents">
          <DialogHeader>
            <DialogTitle className="title">
              {t('Move {{name}} to {{planName}}', {
                name: patientName,
                planName: selectedPlan?.name || selectedPlan?.internalReference,
              })}
            </DialogTitle>
          </DialogHeader>
          <DialogBody className="p-5">
            <StepIndicator currentStep={2} />
            <p className="caption mb-4">
              {t(
                'Compare the benefits before switching. Changes will take effect immediately, and future payments will be updated. Please note that older treatments and perks may be shown.'
              )}
            </p>
            {currentPlan && selectedPlan && (
              <>
                <ComparisonTable
                  currentPlan={currentPlan}
                  selectedPlan={selectedPlan}
                  subscriptionData={subscriptionData}
                />
                <PaymentChangeNotification
                  currentPlanPrice={currentPlan?.price || 0}
                  selectedPlanPrice={selectedPlan?.price || 0}
                />
              </>
            )}

            <Field
              containerClassName="mt-5"
              name="consent"
              component={p => (
                <Checkbox.Label>
                  <FFCheckbox {...p} />
                  <span className="caption">
                    {t(
                      'I confirm I have explicit consent from the member to process this change'
                    )}
                  </span>
                </Checkbox.Label>
              )}
              validate={v => (v ? undefined : t('Please confirm consent'))}
            />
            <FormError />
          </DialogBody>
          <DialogFooter>
            <Button type="button" onClick={onBack}>
              {t('Back')}
            </Button>
            <Button variant="primary" type="submit" loading={submitting}>
              {t('Confirm changes')}
            </Button>
          </DialogFooter>
        </form>
      )}
    </Form>
  )
}

export default ReviewStep
