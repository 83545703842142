import { plansorchestrator } from '@tabeo/platform-api-client'
import Resource, { createResourceHook } from '@tabeo/resync'
import { useRouteMatch } from 'react-router-dom'
import api from 'utils/platform-api'
import { useVendor } from './Vendor'

interface Params {
  id: string
  vendorId: string
}

class SubscriptionV2 extends Resource<
  plansorchestrator.ModelsGetSubscriptionAggregate,
  Params
> {
  async fetch() {
    const response =
      await api.plansorchestrator.subscriptions.apiV1OrchestratorPlansSubscriptionsSubscriptionIdGet(
        {
          vendorId: this.params.vendorId,
          subscriptionId: this.params.id,
        }
      )

    this.data = response.subscription

    return this.data
  }

  registerClaim = async (
    payload: plansorchestrator.ModelsRegisterClaimRequest
  ) => {
    await api.plansorchestrator.subscriptions.apiV1OrchestratorPlansSubscriptionsSubscriptionIdClaimPatch(
      {
        subscriptionId: this.params.id,
        body: {
          ...payload,
        },
      }
    )

    await this.fetch()
  }

  cancel = async () => {
    await api.plansorchestrator.subscriptions.apiV1OrchestratorPlansSubscriptionsSubscriptionIdCancelPatch(
      {
        subscriptionId: this.params.id,
      }
    )

    await this.fetch()
  }

  sendReminder = async () => {
    await api.plansorchestrator.subscriptions.apiV1OrchestratorPlansSubscriptionsSubscriptionIdInviteResendPost(
      {
        subscriptionId: this.params.id,
      }
    )
  }

  swapPlan = async (planId: string) => {
    await api.plansorchestrator.subscriptions.apiV1OrchestratorPlansSubscriptionsSubscriptionIdPlanPatch(
      {
        subscriptionId: this.params.id,
        body: {
          planId,
        },
      }
    )

    await this.fetch()
  }

  sendDirectDebitUpdate = async () => {
    await api.plansorchestrator.subscriptions.apiV1OrchestratorPlansSubscriptionsSubscriptionIdRecreateDirectDebitPost(
      {
        subscriptionId: this.params.id,
      }
    )
  }
}

export default SubscriptionV2
export const useResource = createResourceHook(SubscriptionV2)
export function useSubscriptionV2(p?: Params | (() => Params)) {
  const { data: vendor } = useVendor()

  const match = useRouteMatch<{ subscriptionId?: string }>({
    path: ['/subscriptions/:subscriptionId'],
  })

  let params: Params | undefined
  if (p instanceof Function) {
    params = p()
  } else {
    params = p
  }

  const {
    params: { subscriptionId },
  } = match || { params: {} }

  const subscriptionIdParam = params?.id || subscriptionId
  const vendorIdParam = params?.vendorId || vendor?.id

  return useResource(() => {
    if (!subscriptionIdParam) {
      throw new Error('Subscription ID is null')
    }

    if (!vendorIdParam) {
      throw new Error('Vendor ID is null')
    }

    return {
      id: subscriptionIdParam,
      vendorId: vendorIdParam,
    }
  })
}
