import { Dialog, DialogContent, DialogTrigger, toast } from '@tabeo/scarf2'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePlansV2 } from 'resources/PlansV2'
import { usePlanV2 } from 'resources/PlanV2'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'
import ReviewStep from './ReviewStep'
import SelectPlanStep from './SelectPlanStep'
import { formSchema } from './types'

function SwapPlanModal({ children }: { children: ReactNode }) {
  const { resource, data: subscriptionData } = useSubscriptionV2()
  const { data: plansData } = usePlansV2()
  const plans = useMemo(() => plansData?.items ?? [], [plansData])
  const [currentStep, setCurrentStep] = useState(1)
  const [selectedPlanId, setSelectedPlanId] = useState<string>('')
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const { data: selectedPlan } = usePlanV2({
    id: selectedPlanId,
  })

  const { data: currentPlan } = usePlanV2({
    id: subscriptionData?.plan?.id,
  })

  const patientName = subscriptionData?.patient?.firstName

  const handleSubmit = async (values: any) => {
    try {
      const parsedValues = formSchema.parse(values)

      if (currentStep === 1) {
        // Set the selected plan id to trigger a resync resource fetch
        setSelectedPlanId(parsedValues.planId)

        // 500ms delay to ensure the plan is loaded
        await new Promise(resolve => {
          setTimeout(resolve, 500)
        })

        // Move to review step
        setCurrentStep(2)
        return
      }

      // Final submission
      await resource?.swapPlan(selectedPlanId)

      // Close the modal
      setOpen(false)

      toast.success(t('Plan has been updated'))
    } catch (error: any) {
      return {
        formError: error.message,
      }
    }
  }

  // Clean up when the modal is closed
  useEffect(() => {
    if (open) {
      setSelectedPlanId('')
      setCurrentStep(1)
    }
  }, [open])

  const handleBack = () => {
    setCurrentStep(1)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-[620px]">
        {currentStep === 1 ? (
          <SelectPlanStep
            selectedPlanId={selectedPlanId}
            plans={plans}
            currentPlanId={subscriptionData?.plan?.id}
            patientName={patientName}
            onSubmit={handleSubmit}
          />
        ) : (
          <ReviewStep
            currentPlan={currentPlan}
            selectedPlan={selectedPlan}
            subscriptionData={subscriptionData}
            selectedPlanId={selectedPlanId}
            patientName={patientName}
            onSubmit={handleSubmit}
            onBack={handleBack}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default SwapPlanModal
