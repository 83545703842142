var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// src/generated/orchestrators/plans/index.ts
var plans_exports = {};
__export(plans_exports, {
  BASE_PATH: () => BASE_PATH,
  BaseAPI: () => BaseAPI,
  BlobApiResponse: () => BlobApiResponse,
  COLLECTION_FORMATS: () => COLLECTION_FORMATS,
  Configuration: () => Configuration,
  DefaultConfig: () => DefaultConfig,
  DirectdebitApi: () => DirectdebitApi,
  FetchError: () => FetchError,
  JSONApiResponse: () => JSONApiResponse,
  ModelsAcceptSubscriptionAddressFromJSON: () => ModelsAcceptSubscriptionAddressFromJSON,
  ModelsAcceptSubscriptionAddressFromJSONTyped: () => ModelsAcceptSubscriptionAddressFromJSONTyped,
  ModelsAcceptSubscriptionAddressToJSON: () => ModelsAcceptSubscriptionAddressToJSON,
  ModelsAcceptSubscriptionCustomerDataFromJSON: () => ModelsAcceptSubscriptionCustomerDataFromJSON,
  ModelsAcceptSubscriptionCustomerDataFromJSONTyped: () => ModelsAcceptSubscriptionCustomerDataFromJSONTyped,
  ModelsAcceptSubscriptionCustomerDataToJSON: () => ModelsAcceptSubscriptionCustomerDataToJSON,
  ModelsAcceptSubscriptionInviteRequestFromJSON: () => ModelsAcceptSubscriptionInviteRequestFromJSON,
  ModelsAcceptSubscriptionInviteRequestFromJSONTyped: () => ModelsAcceptSubscriptionInviteRequestFromJSONTyped,
  ModelsAcceptSubscriptionInviteRequestToJSON: () => ModelsAcceptSubscriptionInviteRequestToJSON,
  ModelsAccountLinkFromJSON: () => ModelsAccountLinkFromJSON,
  ModelsAccountLinkFromJSONTyped: () => ModelsAccountLinkFromJSONTyped,
  ModelsAccountLinkToJSON: () => ModelsAccountLinkToJSON,
  ModelsApplyPlansSubscriptionRequestFromJSON: () => ModelsApplyPlansSubscriptionRequestFromJSON,
  ModelsApplyPlansSubscriptionRequestFromJSONTyped: () => ModelsApplyPlansSubscriptionRequestFromJSONTyped,
  ModelsApplyPlansSubscriptionRequestToJSON: () => ModelsApplyPlansSubscriptionRequestToJSON,
  ModelsApplyPlansSubscriptionResponseFromJSON: () => ModelsApplyPlansSubscriptionResponseFromJSON,
  ModelsApplyPlansSubscriptionResponseFromJSONTyped: () => ModelsApplyPlansSubscriptionResponseFromJSONTyped,
  ModelsApplyPlansSubscriptionResponseToJSON: () => ModelsApplyPlansSubscriptionResponseToJSON,
  ModelsBankAccountFromJSON: () => ModelsBankAccountFromJSON,
  ModelsBankAccountFromJSONTyped: () => ModelsBankAccountFromJSONTyped,
  ModelsBankAccountToJSON: () => ModelsBankAccountToJSON,
  ModelsCancelSubscriptionResponseFromJSON: () => ModelsCancelSubscriptionResponseFromJSON,
  ModelsCancelSubscriptionResponseFromJSONTyped: () => ModelsCancelSubscriptionResponseFromJSONTyped,
  ModelsCancelSubscriptionResponseToJSON: () => ModelsCancelSubscriptionResponseToJSON,
  ModelsCapabilitiesFromJSON: () => ModelsCapabilitiesFromJSON,
  ModelsCapabilitiesFromJSONTyped: () => ModelsCapabilitiesFromJSONTyped,
  ModelsCapabilitiesToJSON: () => ModelsCapabilitiesToJSON,
  ModelsChangePlanStatusRequestFromJSON: () => ModelsChangePlanStatusRequestFromJSON,
  ModelsChangePlanStatusRequestFromJSONTyped: () => ModelsChangePlanStatusRequestFromJSONTyped,
  ModelsChangePlanStatusRequestToJSON: () => ModelsChangePlanStatusRequestToJSON,
  ModelsChangePlanStatusResponseFromJSON: () => ModelsChangePlanStatusResponseFromJSON,
  ModelsChangePlanStatusResponseFromJSONTyped: () => ModelsChangePlanStatusResponseFromJSONTyped,
  ModelsChangePlanStatusResponseToJSON: () => ModelsChangePlanStatusResponseToJSON,
  ModelsChangeSubscriptionClinicianRequestFromJSON: () => ModelsChangeSubscriptionClinicianRequestFromJSON,
  ModelsChangeSubscriptionClinicianRequestFromJSONTyped: () => ModelsChangeSubscriptionClinicianRequestFromJSONTyped,
  ModelsChangeSubscriptionClinicianRequestToJSON: () => ModelsChangeSubscriptionClinicianRequestToJSON,
  ModelsChangeSubscriptionStatusRequestFromJSON: () => ModelsChangeSubscriptionStatusRequestFromJSON,
  ModelsChangeSubscriptionStatusRequestFromJSONTyped: () => ModelsChangeSubscriptionStatusRequestFromJSONTyped,
  ModelsChangeSubscriptionStatusRequestToJSON: () => ModelsChangeSubscriptionStatusRequestToJSON,
  ModelsClaimFromJSON: () => ModelsClaimFromJSON,
  ModelsClaimFromJSONTyped: () => ModelsClaimFromJSONTyped,
  ModelsClaimToJSON: () => ModelsClaimToJSON,
  ModelsClinicianFromJSON: () => ModelsClinicianFromJSON,
  ModelsClinicianFromJSONTyped: () => ModelsClinicianFromJSONTyped,
  ModelsClinicianToJSON: () => ModelsClinicianToJSON,
  ModelsConnectedAccountFromJSON: () => ModelsConnectedAccountFromJSON,
  ModelsConnectedAccountFromJSONTyped: () => ModelsConnectedAccountFromJSONTyped,
  ModelsConnectedAccountToJSON: () => ModelsConnectedAccountToJSON,
  ModelsCreateDirectDebitSubscriptionRequestFromJSON: () => ModelsCreateDirectDebitSubscriptionRequestFromJSON,
  ModelsCreateDirectDebitSubscriptionRequestFromJSONTyped: () => ModelsCreateDirectDebitSubscriptionRequestFromJSONTyped,
  ModelsCreateDirectDebitSubscriptionRequestToJSON: () => ModelsCreateDirectDebitSubscriptionRequestToJSON,
  ModelsCreateFeatureFlagRequestFromJSON: () => ModelsCreateFeatureFlagRequestFromJSON,
  ModelsCreateFeatureFlagRequestFromJSONTyped: () => ModelsCreateFeatureFlagRequestFromJSONTyped,
  ModelsCreateFeatureFlagRequestToJSON: () => ModelsCreateFeatureFlagRequestToJSON,
  ModelsCreateInvitePlansSubscriptionRequestFromJSON: () => ModelsCreateInvitePlansSubscriptionRequestFromJSON,
  ModelsCreateInvitePlansSubscriptionRequestFromJSONTyped: () => ModelsCreateInvitePlansSubscriptionRequestFromJSONTyped,
  ModelsCreateInvitePlansSubscriptionRequestToJSON: () => ModelsCreateInvitePlansSubscriptionRequestToJSON,
  ModelsCreateInvitePlansSubscriptionResponseFromJSON: () => ModelsCreateInvitePlansSubscriptionResponseFromJSON,
  ModelsCreateInvitePlansSubscriptionResponseFromJSONTyped: () => ModelsCreateInvitePlansSubscriptionResponseFromJSONTyped,
  ModelsCreateInvitePlansSubscriptionResponseToJSON: () => ModelsCreateInvitePlansSubscriptionResponseToJSON,
  ModelsCreatePerkParamsFromJSON: () => ModelsCreatePerkParamsFromJSON,
  ModelsCreatePerkParamsFromJSONTyped: () => ModelsCreatePerkParamsFromJSONTyped,
  ModelsCreatePerkParamsToJSON: () => ModelsCreatePerkParamsToJSON,
  ModelsCreatePlanRequestFromJSON: () => ModelsCreatePlanRequestFromJSON,
  ModelsCreatePlanRequestFromJSONTyped: () => ModelsCreatePlanRequestFromJSONTyped,
  ModelsCreatePlanRequestToJSON: () => ModelsCreatePlanRequestToJSON,
  ModelsCreatePlanResponseFromJSON: () => ModelsCreatePlanResponseFromJSON,
  ModelsCreatePlanResponseFromJSONTyped: () => ModelsCreatePlanResponseFromJSONTyped,
  ModelsCreatePlanResponseToJSON: () => ModelsCreatePlanResponseToJSON,
  ModelsCreateStripeSubscriptionRequestFromJSON: () => ModelsCreateStripeSubscriptionRequestFromJSON,
  ModelsCreateStripeSubscriptionRequestFromJSONTyped: () => ModelsCreateStripeSubscriptionRequestFromJSONTyped,
  ModelsCreateStripeSubscriptionRequestToJSON: () => ModelsCreateStripeSubscriptionRequestToJSON,
  ModelsCreateTreatmentParamsFromJSON: () => ModelsCreateTreatmentParamsFromJSON,
  ModelsCreateTreatmentParamsFromJSONTyped: () => ModelsCreateTreatmentParamsFromJSONTyped,
  ModelsCreateTreatmentParamsToJSON: () => ModelsCreateTreatmentParamsToJSON,
  ModelsDirectDebitCustomerFromJSON: () => ModelsDirectDebitCustomerFromJSON,
  ModelsDirectDebitCustomerFromJSONTyped: () => ModelsDirectDebitCustomerFromJSONTyped,
  ModelsDirectDebitCustomerToJSON: () => ModelsDirectDebitCustomerToJSON,
  ModelsDirectDebitSubscriptionFromJSON: () => ModelsDirectDebitSubscriptionFromJSON,
  ModelsDirectDebitSubscriptionFromJSONTyped: () => ModelsDirectDebitSubscriptionFromJSONTyped,
  ModelsDirectDebitSubscriptionToJSON: () => ModelsDirectDebitSubscriptionToJSON,
  ModelsFeatureFlagFromJSON: () => ModelsFeatureFlagFromJSON,
  ModelsFeatureFlagFromJSONTyped: () => ModelsFeatureFlagFromJSONTyped,
  ModelsFeatureFlagToJSON: () => ModelsFeatureFlagToJSON,
  ModelsGetInviteCustomerFromJSON: () => ModelsGetInviteCustomerFromJSON,
  ModelsGetInviteCustomerFromJSONTyped: () => ModelsGetInviteCustomerFromJSONTyped,
  ModelsGetInviteCustomerToJSON: () => ModelsGetInviteCustomerToJSON,
  ModelsGetInviteDocumentFromJSON: () => ModelsGetInviteDocumentFromJSON,
  ModelsGetInviteDocumentFromJSONTyped: () => ModelsGetInviteDocumentFromJSONTyped,
  ModelsGetInviteDocumentToJSON: () => ModelsGetInviteDocumentToJSON,
  ModelsGetInvitePerkFromJSON: () => ModelsGetInvitePerkFromJSON,
  ModelsGetInvitePerkFromJSONTyped: () => ModelsGetInvitePerkFromJSONTyped,
  ModelsGetInvitePerkToJSON: () => ModelsGetInvitePerkToJSON,
  ModelsGetInvitePlanFromJSON: () => ModelsGetInvitePlanFromJSON,
  ModelsGetInvitePlanFromJSONTyped: () => ModelsGetInvitePlanFromJSONTyped,
  ModelsGetInvitePlanToJSON: () => ModelsGetInvitePlanToJSON,
  ModelsGetInviteStatusFromJSON: () => ModelsGetInviteStatusFromJSON,
  ModelsGetInviteStatusFromJSONTyped: () => ModelsGetInviteStatusFromJSONTyped,
  ModelsGetInviteStatusToJSON: () => ModelsGetInviteStatusToJSON,
  ModelsGetInviteSubscriptionFromJSON: () => ModelsGetInviteSubscriptionFromJSON,
  ModelsGetInviteSubscriptionFromJSONTyped: () => ModelsGetInviteSubscriptionFromJSONTyped,
  ModelsGetInviteSubscriptionToJSON: () => ModelsGetInviteSubscriptionToJSON,
  ModelsGetInviteTreatmentFromJSON: () => ModelsGetInviteTreatmentFromJSON,
  ModelsGetInviteTreatmentFromJSONTyped: () => ModelsGetInviteTreatmentFromJSONTyped,
  ModelsGetInviteTreatmentToJSON: () => ModelsGetInviteTreatmentToJSON,
  ModelsGetInviteVendorFromJSON: () => ModelsGetInviteVendorFromJSON,
  ModelsGetInviteVendorFromJSONTyped: () => ModelsGetInviteVendorFromJSONTyped,
  ModelsGetInviteVendorToJSON: () => ModelsGetInviteVendorToJSON,
  ModelsGetPlanPublicResponseFromJSON: () => ModelsGetPlanPublicResponseFromJSON,
  ModelsGetPlanPublicResponseFromJSONTyped: () => ModelsGetPlanPublicResponseFromJSONTyped,
  ModelsGetPlanPublicResponseToJSON: () => ModelsGetPlanPublicResponseToJSON,
  ModelsGetPlanResponseFromJSON: () => ModelsGetPlanResponseFromJSON,
  ModelsGetPlanResponseFromJSONTyped: () => ModelsGetPlanResponseFromJSONTyped,
  ModelsGetPlanResponseToJSON: () => ModelsGetPlanResponseToJSON,
  ModelsGetPlansSubscriptionInviteResponseFromJSON: () => ModelsGetPlansSubscriptionInviteResponseFromJSON,
  ModelsGetPlansSubscriptionInviteResponseFromJSONTyped: () => ModelsGetPlansSubscriptionInviteResponseFromJSONTyped,
  ModelsGetPlansSubscriptionInviteResponseToJSON: () => ModelsGetPlansSubscriptionInviteResponseToJSON,
  ModelsGetSubscriptionAggregateFromJSON: () => ModelsGetSubscriptionAggregateFromJSON,
  ModelsGetSubscriptionAggregateFromJSONTyped: () => ModelsGetSubscriptionAggregateFromJSONTyped,
  ModelsGetSubscriptionAggregateToJSON: () => ModelsGetSubscriptionAggregateToJSON,
  ModelsGetSubscriptionResponseFromJSON: () => ModelsGetSubscriptionResponseFromJSON,
  ModelsGetSubscriptionResponseFromJSONTyped: () => ModelsGetSubscriptionResponseFromJSONTyped,
  ModelsGetSubscriptionResponseToJSON: () => ModelsGetSubscriptionResponseToJSON,
  ModelsGetSubscriptionSummaryResponseFromJSON: () => ModelsGetSubscriptionSummaryResponseFromJSON,
  ModelsGetSubscriptionSummaryResponseFromJSONTyped: () => ModelsGetSubscriptionSummaryResponseFromJSONTyped,
  ModelsGetSubscriptionSummaryResponseToJSON: () => ModelsGetSubscriptionSummaryResponseToJSON,
  ModelsGetSubscriptionsCustomerFromJSON: () => ModelsGetSubscriptionsCustomerFromJSON,
  ModelsGetSubscriptionsCustomerFromJSONTyped: () => ModelsGetSubscriptionsCustomerFromJSONTyped,
  ModelsGetSubscriptionsCustomerToJSON: () => ModelsGetSubscriptionsCustomerToJSON,
  ModelsGetSubscriptionsPatientFromJSON: () => ModelsGetSubscriptionsPatientFromJSON,
  ModelsGetSubscriptionsPatientFromJSONTyped: () => ModelsGetSubscriptionsPatientFromJSONTyped,
  ModelsGetSubscriptionsPatientToJSON: () => ModelsGetSubscriptionsPatientToJSON,
  ModelsGetSubscriptionsPlanFromJSON: () => ModelsGetSubscriptionsPlanFromJSON,
  ModelsGetSubscriptionsPlanFromJSONTyped: () => ModelsGetSubscriptionsPlanFromJSONTyped,
  ModelsGetSubscriptionsPlanToJSON: () => ModelsGetSubscriptionsPlanToJSON,
  ModelsGetSubscriptionsSubscriptionFromJSON: () => ModelsGetSubscriptionsSubscriptionFromJSON,
  ModelsGetSubscriptionsSubscriptionFromJSONTyped: () => ModelsGetSubscriptionsSubscriptionFromJSONTyped,
  ModelsGetSubscriptionsSubscriptionToJSON: () => ModelsGetSubscriptionsSubscriptionToJSON,
  ModelsGetSummaryCustomerFromJSON: () => ModelsGetSummaryCustomerFromJSON,
  ModelsGetSummaryCustomerFromJSONTyped: () => ModelsGetSummaryCustomerFromJSONTyped,
  ModelsGetSummaryCustomerToJSON: () => ModelsGetSummaryCustomerToJSON,
  ModelsGetSummaryPatientFromJSON: () => ModelsGetSummaryPatientFromJSON,
  ModelsGetSummaryPatientFromJSONTyped: () => ModelsGetSummaryPatientFromJSONTyped,
  ModelsGetSummaryPatientToJSON: () => ModelsGetSummaryPatientToJSON,
  ModelsGetSummaryPlanFromJSON: () => ModelsGetSummaryPlanFromJSON,
  ModelsGetSummaryPlanFromJSONTyped: () => ModelsGetSummaryPlanFromJSONTyped,
  ModelsGetSummaryPlanToJSON: () => ModelsGetSummaryPlanToJSON,
  ModelsGetSummarySubscriptionFromJSON: () => ModelsGetSummarySubscriptionFromJSON,
  ModelsGetSummarySubscriptionFromJSONTyped: () => ModelsGetSummarySubscriptionFromJSONTyped,
  ModelsGetSummarySubscriptionToJSON: () => ModelsGetSummarySubscriptionToJSON,
  ModelsGetSummaryVendorFromJSON: () => ModelsGetSummaryVendorFromJSON,
  ModelsGetSummaryVendorFromJSONTyped: () => ModelsGetSummaryVendorFromJSONTyped,
  ModelsGetSummaryVendorToJSON: () => ModelsGetSummaryVendorToJSON,
  ModelsGetVendorPublicResponseFromJSON: () => ModelsGetVendorPublicResponseFromJSON,
  ModelsGetVendorPublicResponseFromJSONTyped: () => ModelsGetVendorPublicResponseFromJSONTyped,
  ModelsGetVendorPublicResponseToJSON: () => ModelsGetVendorPublicResponseToJSON,
  ModelsGetVendorResponseFromJSON: () => ModelsGetVendorResponseFromJSON,
  ModelsGetVendorResponseFromJSONTyped: () => ModelsGetVendorResponseFromJSONTyped,
  ModelsGetVendorResponseToJSON: () => ModelsGetVendorResponseToJSON,
  ModelsOnboardDirectDebitCustomerRequestFromJSON: () => ModelsOnboardDirectDebitCustomerRequestFromJSON,
  ModelsOnboardDirectDebitCustomerRequestFromJSONTyped: () => ModelsOnboardDirectDebitCustomerRequestFromJSONTyped,
  ModelsOnboardDirectDebitCustomerRequestToJSON: () => ModelsOnboardDirectDebitCustomerRequestToJSON,
  ModelsOnboardDirectDebitCustomerResponseFromJSON: () => ModelsOnboardDirectDebitCustomerResponseFromJSON,
  ModelsOnboardDirectDebitCustomerResponseFromJSONTyped: () => ModelsOnboardDirectDebitCustomerResponseFromJSONTyped,
  ModelsOnboardDirectDebitCustomerResponseToJSON: () => ModelsOnboardDirectDebitCustomerResponseToJSON,
  ModelsOnboardStripeCustomerRequestFromJSON: () => ModelsOnboardStripeCustomerRequestFromJSON,
  ModelsOnboardStripeCustomerRequestFromJSONTyped: () => ModelsOnboardStripeCustomerRequestFromJSONTyped,
  ModelsOnboardStripeCustomerRequestToJSON: () => ModelsOnboardStripeCustomerRequestToJSON,
  ModelsOnboardStripeCustomerResponseFromJSON: () => ModelsOnboardStripeCustomerResponseFromJSON,
  ModelsOnboardStripeCustomerResponseFromJSONTyped: () => ModelsOnboardStripeCustomerResponseFromJSONTyped,
  ModelsOnboardStripeCustomerResponseToJSON: () => ModelsOnboardStripeCustomerResponseToJSON,
  ModelsPerkFromJSON: () => ModelsPerkFromJSON,
  ModelsPerkFromJSONTyped: () => ModelsPerkFromJSONTyped,
  ModelsPerkToJSON: () => ModelsPerkToJSON,
  ModelsPerkUsageFromJSON: () => ModelsPerkUsageFromJSON,
  ModelsPerkUsageFromJSONTyped: () => ModelsPerkUsageFromJSONTyped,
  ModelsPerkUsageToJSON: () => ModelsPerkUsageToJSON,
  ModelsPlanFromJSON: () => ModelsPlanFromJSON,
  ModelsPlanFromJSONTyped: () => ModelsPlanFromJSONTyped,
  ModelsPlanSubscriptionInviteUpdatePatientRequestFromJSON: () => ModelsPlanSubscriptionInviteUpdatePatientRequestFromJSON,
  ModelsPlanSubscriptionInviteUpdatePatientRequestFromJSONTyped: () => ModelsPlanSubscriptionInviteUpdatePatientRequestFromJSONTyped,
  ModelsPlanSubscriptionInviteUpdatePatientRequestToJSON: () => ModelsPlanSubscriptionInviteUpdatePatientRequestToJSON,
  ModelsPlanToJSON: () => ModelsPlanToJSON,
  ModelsRegisterClaimRequestFromJSON: () => ModelsRegisterClaimRequestFromJSON,
  ModelsRegisterClaimRequestFromJSONTyped: () => ModelsRegisterClaimRequestFromJSONTyped,
  ModelsRegisterClaimRequestToJSON: () => ModelsRegisterClaimRequestToJSON,
  ModelsRegisterCustomerFromJSON: () => ModelsRegisterCustomerFromJSON,
  ModelsRegisterCustomerFromJSONTyped: () => ModelsRegisterCustomerFromJSONTyped,
  ModelsRegisterCustomerResponseFromJSON: () => ModelsRegisterCustomerResponseFromJSON,
  ModelsRegisterCustomerResponseFromJSONTyped: () => ModelsRegisterCustomerResponseFromJSONTyped,
  ModelsRegisterCustomerResponseToJSON: () => ModelsRegisterCustomerResponseToJSON,
  ModelsRegisterCustomerToJSON: () => ModelsRegisterCustomerToJSON,
  ModelsRequestedPerkFromJSON: () => ModelsRequestedPerkFromJSON,
  ModelsRequestedPerkFromJSONTyped: () => ModelsRequestedPerkFromJSONTyped,
  ModelsRequestedPerkToJSON: () => ModelsRequestedPerkToJSON,
  ModelsRequestedTreatmentFromJSON: () => ModelsRequestedTreatmentFromJSON,
  ModelsRequestedTreatmentFromJSONTyped: () => ModelsRequestedTreatmentFromJSONTyped,
  ModelsRequestedTreatmentToJSON: () => ModelsRequestedTreatmentToJSON,
  ModelsSelectFeatureFlagsRequestFromJSON: () => ModelsSelectFeatureFlagsRequestFromJSON,
  ModelsSelectFeatureFlagsRequestFromJSONTyped: () => ModelsSelectFeatureFlagsRequestFromJSONTyped,
  ModelsSelectFeatureFlagsRequestToJSON: () => ModelsSelectFeatureFlagsRequestToJSON,
  ModelsSelectPlansAggregateFromJSON: () => ModelsSelectPlansAggregateFromJSON,
  ModelsSelectPlansAggregateFromJSONTyped: () => ModelsSelectPlansAggregateFromJSONTyped,
  ModelsSelectPlansAggregateToJSON: () => ModelsSelectPlansAggregateToJSON,
  ModelsSelectPlansResponseFromJSON: () => ModelsSelectPlansResponseFromJSON,
  ModelsSelectPlansResponseFromJSONTyped: () => ModelsSelectPlansResponseFromJSONTyped,
  ModelsSelectPlansResponseToJSON: () => ModelsSelectPlansResponseToJSON,
  ModelsSelectSubscriptionAggregateFromJSON: () => ModelsSelectSubscriptionAggregateFromJSON,
  ModelsSelectSubscriptionAggregateFromJSONTyped: () => ModelsSelectSubscriptionAggregateFromJSONTyped,
  ModelsSelectSubscriptionAggregateToJSON: () => ModelsSelectSubscriptionAggregateToJSON,
  ModelsSelectSubscriptionsCustomerFromJSON: () => ModelsSelectSubscriptionsCustomerFromJSON,
  ModelsSelectSubscriptionsCustomerFromJSONTyped: () => ModelsSelectSubscriptionsCustomerFromJSONTyped,
  ModelsSelectSubscriptionsCustomerToJSON: () => ModelsSelectSubscriptionsCustomerToJSON,
  ModelsSelectSubscriptionsInviteFromJSON: () => ModelsSelectSubscriptionsInviteFromJSON,
  ModelsSelectSubscriptionsInviteFromJSONTyped: () => ModelsSelectSubscriptionsInviteFromJSONTyped,
  ModelsSelectSubscriptionsInviteToJSON: () => ModelsSelectSubscriptionsInviteToJSON,
  ModelsSelectSubscriptionsPatientFromJSON: () => ModelsSelectSubscriptionsPatientFromJSON,
  ModelsSelectSubscriptionsPatientFromJSONTyped: () => ModelsSelectSubscriptionsPatientFromJSONTyped,
  ModelsSelectSubscriptionsPatientToJSON: () => ModelsSelectSubscriptionsPatientToJSON,
  ModelsSelectSubscriptionsPlanFromJSON: () => ModelsSelectSubscriptionsPlanFromJSON,
  ModelsSelectSubscriptionsPlanFromJSONTyped: () => ModelsSelectSubscriptionsPlanFromJSONTyped,
  ModelsSelectSubscriptionsPlanToJSON: () => ModelsSelectSubscriptionsPlanToJSON,
  ModelsSelectSubscriptionsRequestFromJSON: () => ModelsSelectSubscriptionsRequestFromJSON,
  ModelsSelectSubscriptionsRequestFromJSONTyped: () => ModelsSelectSubscriptionsRequestFromJSONTyped,
  ModelsSelectSubscriptionsRequestToJSON: () => ModelsSelectSubscriptionsRequestToJSON,
  ModelsSelectSubscriptionsResponseFromJSON: () => ModelsSelectSubscriptionsResponseFromJSON,
  ModelsSelectSubscriptionsResponseFromJSONTyped: () => ModelsSelectSubscriptionsResponseFromJSONTyped,
  ModelsSelectSubscriptionsResponseToJSON: () => ModelsSelectSubscriptionsResponseToJSON,
  ModelsSelectSubscriptionsSubscriptionFromJSON: () => ModelsSelectSubscriptionsSubscriptionFromJSON,
  ModelsSelectSubscriptionsSubscriptionFromJSONTyped: () => ModelsSelectSubscriptionsSubscriptionFromJSONTyped,
  ModelsSelectSubscriptionsSubscriptionToJSON: () => ModelsSelectSubscriptionsSubscriptionToJSON,
  ModelsSelectSubscriptionsVendorFromJSON: () => ModelsSelectSubscriptionsVendorFromJSON,
  ModelsSelectSubscriptionsVendorFromJSONTyped: () => ModelsSelectSubscriptionsVendorFromJSONTyped,
  ModelsSelectSubscriptionsVendorToJSON: () => ModelsSelectSubscriptionsVendorToJSON,
  ModelsSetupIntentFromJSON: () => ModelsSetupIntentFromJSON,
  ModelsSetupIntentFromJSONTyped: () => ModelsSetupIntentFromJSONTyped,
  ModelsSetupIntentToJSON: () => ModelsSetupIntentToJSON,
  ModelsSubscriptionStatusFromJSON: () => ModelsSubscriptionStatusFromJSON,
  ModelsSubscriptionStatusFromJSONTyped: () => ModelsSubscriptionStatusFromJSONTyped,
  ModelsSubscriptionStatusToJSON: () => ModelsSubscriptionStatusToJSON,
  ModelsSwapSubscriptionPlanRequestFromJSON: () => ModelsSwapSubscriptionPlanRequestFromJSON,
  ModelsSwapSubscriptionPlanRequestFromJSONTyped: () => ModelsSwapSubscriptionPlanRequestFromJSONTyped,
  ModelsSwapSubscriptionPlanRequestToJSON: () => ModelsSwapSubscriptionPlanRequestToJSON,
  ModelsTreatmentFromJSON: () => ModelsTreatmentFromJSON,
  ModelsTreatmentFromJSONTyped: () => ModelsTreatmentFromJSONTyped,
  ModelsTreatmentToJSON: () => ModelsTreatmentToJSON,
  ModelsTreatmentUsageFromJSON: () => ModelsTreatmentUsageFromJSON,
  ModelsTreatmentUsageFromJSONTyped: () => ModelsTreatmentUsageFromJSONTyped,
  ModelsTreatmentUsageToJSON: () => ModelsTreatmentUsageToJSON,
  ModelsUpdatePatientOnInviteFromJSON: () => ModelsUpdatePatientOnInviteFromJSON,
  ModelsUpdatePatientOnInviteFromJSONTyped: () => ModelsUpdatePatientOnInviteFromJSONTyped,
  ModelsUpdatePatientOnInviteToJSON: () => ModelsUpdatePatientOnInviteToJSON,
  ModelsUpdatePerkParamsFromJSON: () => ModelsUpdatePerkParamsFromJSON,
  ModelsUpdatePerkParamsFromJSONTyped: () => ModelsUpdatePerkParamsFromJSONTyped,
  ModelsUpdatePerkParamsToJSON: () => ModelsUpdatePerkParamsToJSON,
  ModelsUpdatePlanRequestFromJSON: () => ModelsUpdatePlanRequestFromJSON,
  ModelsUpdatePlanRequestFromJSONTyped: () => ModelsUpdatePlanRequestFromJSONTyped,
  ModelsUpdatePlanRequestToJSON: () => ModelsUpdatePlanRequestToJSON,
  ModelsUpdatePlanResponseFromJSON: () => ModelsUpdatePlanResponseFromJSON,
  ModelsUpdatePlanResponseFromJSONTyped: () => ModelsUpdatePlanResponseFromJSONTyped,
  ModelsUpdatePlanResponseToJSON: () => ModelsUpdatePlanResponseToJSON,
  ModelsUpdateSubscriptionPatientConsumerRequestFromJSON: () => ModelsUpdateSubscriptionPatientConsumerRequestFromJSON,
  ModelsUpdateSubscriptionPatientConsumerRequestFromJSONTyped: () => ModelsUpdateSubscriptionPatientConsumerRequestFromJSONTyped,
  ModelsUpdateSubscriptionPatientConsumerRequestToJSON: () => ModelsUpdateSubscriptionPatientConsumerRequestToJSON,
  ModelsUpdateSubscriptionPatientVendorRequestFromJSON: () => ModelsUpdateSubscriptionPatientVendorRequestFromJSON,
  ModelsUpdateSubscriptionPatientVendorRequestFromJSONTyped: () => ModelsUpdateSubscriptionPatientVendorRequestFromJSONTyped,
  ModelsUpdateSubscriptionPatientVendorRequestToJSON: () => ModelsUpdateSubscriptionPatientVendorRequestToJSON,
  ModelsUpdateTreatmentParamsFromJSON: () => ModelsUpdateTreatmentParamsFromJSON,
  ModelsUpdateTreatmentParamsFromJSONTyped: () => ModelsUpdateTreatmentParamsFromJSONTyped,
  ModelsUpdateTreatmentParamsToJSON: () => ModelsUpdateTreatmentParamsToJSON,
  ModelsUpsertFeatureFlagRequestFromJSON: () => ModelsUpsertFeatureFlagRequestFromJSON,
  ModelsUpsertFeatureFlagRequestFromJSONTyped: () => ModelsUpsertFeatureFlagRequestFromJSONTyped,
  ModelsUpsertFeatureFlagRequestToJSON: () => ModelsUpsertFeatureFlagRequestToJSON,
  ModelsUsedPerksFromJSON: () => ModelsUsedPerksFromJSON,
  ModelsUsedPerksFromJSONTyped: () => ModelsUsedPerksFromJSONTyped,
  ModelsUsedPerksToJSON: () => ModelsUsedPerksToJSON,
  ModelsUsedTreatmentsFromJSON: () => ModelsUsedTreatmentsFromJSON,
  ModelsUsedTreatmentsFromJSONTyped: () => ModelsUsedTreatmentsFromJSONTyped,
  ModelsUsedTreatmentsToJSON: () => ModelsUsedTreatmentsToJSON,
  ModelsVendorClinicianFromJSON: () => ModelsVendorClinicianFromJSON,
  ModelsVendorClinicianFromJSONTyped: () => ModelsVendorClinicianFromJSONTyped,
  ModelsVendorClinicianToJSON: () => ModelsVendorClinicianToJSON,
  ModelsVendorFeatureFlagFromJSON: () => ModelsVendorFeatureFlagFromJSON,
  ModelsVendorFeatureFlagFromJSONTyped: () => ModelsVendorFeatureFlagFromJSONTyped,
  ModelsVendorFeatureFlagToJSON: () => ModelsVendorFeatureFlagToJSON,
  ModelsVendorFromJSON: () => ModelsVendorFromJSON,
  ModelsVendorFromJSONTyped: () => ModelsVendorFromJSONTyped,
  ModelsVendorToJSON: () => ModelsVendorToJSON,
  PlansApi: () => PlansApi,
  RequiredError: () => RequiredError,
  ResponseError: () => ResponseError,
  StripeApi: () => StripeApi,
  SubscriptionsApi: () => SubscriptionsApi,
  TextApiResponse: () => TextApiResponse,
  ThttpErrResponseFromJSON: () => ThttpErrResponseFromJSON,
  ThttpErrResponseFromJSONTyped: () => ThttpErrResponseFromJSONTyped,
  ThttpErrResponseToJSON: () => ThttpErrResponseToJSON,
  ThttpPaginationSummaryFromJSON: () => ThttpPaginationSummaryFromJSON,
  ThttpPaginationSummaryFromJSONTyped: () => ThttpPaginationSummaryFromJSONTyped,
  ThttpPaginationSummaryToJSON: () => ThttpPaginationSummaryToJSON,
  VendorsApi: () => VendorsApi,
  VoidApiResponse: () => VoidApiResponse,
  canConsumeForm: () => canConsumeForm,
  exists: () => exists,
  instanceOfModelsAcceptSubscriptionAddress: () => instanceOfModelsAcceptSubscriptionAddress,
  instanceOfModelsAcceptSubscriptionCustomerData: () => instanceOfModelsAcceptSubscriptionCustomerData,
  instanceOfModelsAcceptSubscriptionInviteRequest: () => instanceOfModelsAcceptSubscriptionInviteRequest,
  instanceOfModelsAccountLink: () => instanceOfModelsAccountLink,
  instanceOfModelsApplyPlansSubscriptionRequest: () => instanceOfModelsApplyPlansSubscriptionRequest,
  instanceOfModelsApplyPlansSubscriptionResponse: () => instanceOfModelsApplyPlansSubscriptionResponse,
  instanceOfModelsBankAccount: () => instanceOfModelsBankAccount,
  instanceOfModelsCancelSubscriptionResponse: () => instanceOfModelsCancelSubscriptionResponse,
  instanceOfModelsCapabilities: () => instanceOfModelsCapabilities,
  instanceOfModelsChangePlanStatusRequest: () => instanceOfModelsChangePlanStatusRequest,
  instanceOfModelsChangePlanStatusResponse: () => instanceOfModelsChangePlanStatusResponse,
  instanceOfModelsChangeSubscriptionClinicianRequest: () => instanceOfModelsChangeSubscriptionClinicianRequest,
  instanceOfModelsChangeSubscriptionStatusRequest: () => instanceOfModelsChangeSubscriptionStatusRequest,
  instanceOfModelsClaim: () => instanceOfModelsClaim,
  instanceOfModelsClinician: () => instanceOfModelsClinician,
  instanceOfModelsConnectedAccount: () => instanceOfModelsConnectedAccount,
  instanceOfModelsCreateDirectDebitSubscriptionRequest: () => instanceOfModelsCreateDirectDebitSubscriptionRequest,
  instanceOfModelsCreateFeatureFlagRequest: () => instanceOfModelsCreateFeatureFlagRequest,
  instanceOfModelsCreateInvitePlansSubscriptionRequest: () => instanceOfModelsCreateInvitePlansSubscriptionRequest,
  instanceOfModelsCreateInvitePlansSubscriptionResponse: () => instanceOfModelsCreateInvitePlansSubscriptionResponse,
  instanceOfModelsCreatePerkParams: () => instanceOfModelsCreatePerkParams,
  instanceOfModelsCreatePlanRequest: () => instanceOfModelsCreatePlanRequest,
  instanceOfModelsCreatePlanResponse: () => instanceOfModelsCreatePlanResponse,
  instanceOfModelsCreateStripeSubscriptionRequest: () => instanceOfModelsCreateStripeSubscriptionRequest,
  instanceOfModelsCreateTreatmentParams: () => instanceOfModelsCreateTreatmentParams,
  instanceOfModelsDirectDebitCustomer: () => instanceOfModelsDirectDebitCustomer,
  instanceOfModelsDirectDebitSubscription: () => instanceOfModelsDirectDebitSubscription,
  instanceOfModelsFeatureFlag: () => instanceOfModelsFeatureFlag,
  instanceOfModelsGetInviteCustomer: () => instanceOfModelsGetInviteCustomer,
  instanceOfModelsGetInviteDocument: () => instanceOfModelsGetInviteDocument,
  instanceOfModelsGetInvitePerk: () => instanceOfModelsGetInvitePerk,
  instanceOfModelsGetInvitePlan: () => instanceOfModelsGetInvitePlan,
  instanceOfModelsGetInviteStatus: () => instanceOfModelsGetInviteStatus,
  instanceOfModelsGetInviteSubscription: () => instanceOfModelsGetInviteSubscription,
  instanceOfModelsGetInviteTreatment: () => instanceOfModelsGetInviteTreatment,
  instanceOfModelsGetInviteVendor: () => instanceOfModelsGetInviteVendor,
  instanceOfModelsGetPlanPublicResponse: () => instanceOfModelsGetPlanPublicResponse,
  instanceOfModelsGetPlanResponse: () => instanceOfModelsGetPlanResponse,
  instanceOfModelsGetPlansSubscriptionInviteResponse: () => instanceOfModelsGetPlansSubscriptionInviteResponse,
  instanceOfModelsGetSubscriptionAggregate: () => instanceOfModelsGetSubscriptionAggregate,
  instanceOfModelsGetSubscriptionResponse: () => instanceOfModelsGetSubscriptionResponse,
  instanceOfModelsGetSubscriptionSummaryResponse: () => instanceOfModelsGetSubscriptionSummaryResponse,
  instanceOfModelsGetSubscriptionsCustomer: () => instanceOfModelsGetSubscriptionsCustomer,
  instanceOfModelsGetSubscriptionsPatient: () => instanceOfModelsGetSubscriptionsPatient,
  instanceOfModelsGetSubscriptionsPlan: () => instanceOfModelsGetSubscriptionsPlan,
  instanceOfModelsGetSubscriptionsSubscription: () => instanceOfModelsGetSubscriptionsSubscription,
  instanceOfModelsGetSummaryCustomer: () => instanceOfModelsGetSummaryCustomer,
  instanceOfModelsGetSummaryPatient: () => instanceOfModelsGetSummaryPatient,
  instanceOfModelsGetSummaryPlan: () => instanceOfModelsGetSummaryPlan,
  instanceOfModelsGetSummarySubscription: () => instanceOfModelsGetSummarySubscription,
  instanceOfModelsGetSummaryVendor: () => instanceOfModelsGetSummaryVendor,
  instanceOfModelsGetVendorPublicResponse: () => instanceOfModelsGetVendorPublicResponse,
  instanceOfModelsGetVendorResponse: () => instanceOfModelsGetVendorResponse,
  instanceOfModelsOnboardDirectDebitCustomerRequest: () => instanceOfModelsOnboardDirectDebitCustomerRequest,
  instanceOfModelsOnboardDirectDebitCustomerResponse: () => instanceOfModelsOnboardDirectDebitCustomerResponse,
  instanceOfModelsOnboardStripeCustomerRequest: () => instanceOfModelsOnboardStripeCustomerRequest,
  instanceOfModelsOnboardStripeCustomerResponse: () => instanceOfModelsOnboardStripeCustomerResponse,
  instanceOfModelsPerk: () => instanceOfModelsPerk,
  instanceOfModelsPerkUsage: () => instanceOfModelsPerkUsage,
  instanceOfModelsPlan: () => instanceOfModelsPlan,
  instanceOfModelsPlanSubscriptionInviteUpdatePatientRequest: () => instanceOfModelsPlanSubscriptionInviteUpdatePatientRequest,
  instanceOfModelsRegisterClaimRequest: () => instanceOfModelsRegisterClaimRequest,
  instanceOfModelsRegisterCustomer: () => instanceOfModelsRegisterCustomer,
  instanceOfModelsRegisterCustomerResponse: () => instanceOfModelsRegisterCustomerResponse,
  instanceOfModelsRequestedPerk: () => instanceOfModelsRequestedPerk,
  instanceOfModelsRequestedTreatment: () => instanceOfModelsRequestedTreatment,
  instanceOfModelsSelectFeatureFlagsRequest: () => instanceOfModelsSelectFeatureFlagsRequest,
  instanceOfModelsSelectPlansAggregate: () => instanceOfModelsSelectPlansAggregate,
  instanceOfModelsSelectPlansResponse: () => instanceOfModelsSelectPlansResponse,
  instanceOfModelsSelectSubscriptionAggregate: () => instanceOfModelsSelectSubscriptionAggregate,
  instanceOfModelsSelectSubscriptionsCustomer: () => instanceOfModelsSelectSubscriptionsCustomer,
  instanceOfModelsSelectSubscriptionsInvite: () => instanceOfModelsSelectSubscriptionsInvite,
  instanceOfModelsSelectSubscriptionsPatient: () => instanceOfModelsSelectSubscriptionsPatient,
  instanceOfModelsSelectSubscriptionsPlan: () => instanceOfModelsSelectSubscriptionsPlan,
  instanceOfModelsSelectSubscriptionsRequest: () => instanceOfModelsSelectSubscriptionsRequest,
  instanceOfModelsSelectSubscriptionsResponse: () => instanceOfModelsSelectSubscriptionsResponse,
  instanceOfModelsSelectSubscriptionsSubscription: () => instanceOfModelsSelectSubscriptionsSubscription,
  instanceOfModelsSelectSubscriptionsVendor: () => instanceOfModelsSelectSubscriptionsVendor,
  instanceOfModelsSetupIntent: () => instanceOfModelsSetupIntent,
  instanceOfModelsSubscriptionStatus: () => instanceOfModelsSubscriptionStatus,
  instanceOfModelsSwapSubscriptionPlanRequest: () => instanceOfModelsSwapSubscriptionPlanRequest,
  instanceOfModelsTreatment: () => instanceOfModelsTreatment,
  instanceOfModelsTreatmentUsage: () => instanceOfModelsTreatmentUsage,
  instanceOfModelsUpdatePatientOnInvite: () => instanceOfModelsUpdatePatientOnInvite,
  instanceOfModelsUpdatePerkParams: () => instanceOfModelsUpdatePerkParams,
  instanceOfModelsUpdatePlanRequest: () => instanceOfModelsUpdatePlanRequest,
  instanceOfModelsUpdatePlanResponse: () => instanceOfModelsUpdatePlanResponse,
  instanceOfModelsUpdateSubscriptionPatientConsumerRequest: () => instanceOfModelsUpdateSubscriptionPatientConsumerRequest,
  instanceOfModelsUpdateSubscriptionPatientVendorRequest: () => instanceOfModelsUpdateSubscriptionPatientVendorRequest,
  instanceOfModelsUpdateTreatmentParams: () => instanceOfModelsUpdateTreatmentParams,
  instanceOfModelsUpsertFeatureFlagRequest: () => instanceOfModelsUpsertFeatureFlagRequest,
  instanceOfModelsUsedPerks: () => instanceOfModelsUsedPerks,
  instanceOfModelsUsedTreatments: () => instanceOfModelsUsedTreatments,
  instanceOfModelsVendor: () => instanceOfModelsVendor,
  instanceOfModelsVendorClinician: () => instanceOfModelsVendorClinician,
  instanceOfModelsVendorFeatureFlag: () => instanceOfModelsVendorFeatureFlag,
  instanceOfThttpErrResponse: () => instanceOfThttpErrResponse,
  instanceOfThttpPaginationSummary: () => instanceOfThttpPaginationSummary,
  mapValues: () => mapValues,
  querystring: () => querystring
});

// src/generated/orchestrators/plans/runtime.ts
var BASE_PATH = "http://localhost:8087".replace(/\/+$/, "");
var Configuration = class {
  constructor(configuration = {}) {
    this.configuration = configuration;
  }
  set config(configuration) {
    this.configuration = configuration;
  }
  get basePath() {
    return this.configuration.basePath != null ? this.configuration.basePath : BASE_PATH;
  }
  get fetchApi() {
    return this.configuration.fetchApi;
  }
  get middleware() {
    return this.configuration.middleware || [];
  }
  get queryParamsStringify() {
    return this.configuration.queryParamsStringify || querystring;
  }
  get username() {
    return this.configuration.username;
  }
  get password() {
    return this.configuration.password;
  }
  get apiKey() {
    const apiKey = this.configuration.apiKey;
    if (apiKey) {
      return typeof apiKey === "function" ? apiKey : () => apiKey;
    }
    return void 0;
  }
  get accessToken() {
    const accessToken = this.configuration.accessToken;
    if (accessToken) {
      return typeof accessToken === "function" ? accessToken : async () => accessToken;
    }
    return void 0;
  }
  get headers() {
    return this.configuration.headers;
  }
  get credentials() {
    return this.configuration.credentials;
  }
};
var DefaultConfig = new Configuration();
var BaseAPI = class _BaseAPI {
  constructor(configuration = DefaultConfig) {
    this.configuration = configuration;
    this.middleware = configuration.middleware;
  }
  static jsonRegex = new RegExp("^(:?application/json|[^;/ 	]+/[^;/ 	]+[+]json)[ 	]*(:?;.*)?$", "i");
  middleware;
  withMiddleware(...middlewares) {
    const next = this.clone();
    next.middleware = next.middleware.concat(...middlewares);
    return next;
  }
  withPreMiddleware(...preMiddlewares) {
    const middlewares = preMiddlewares.map((pre) => ({ pre }));
    return this.withMiddleware(...middlewares);
  }
  withPostMiddleware(...postMiddlewares) {
    const middlewares = postMiddlewares.map((post) => ({ post }));
    return this.withMiddleware(...middlewares);
  }
  /**
   * Check if the given MIME is a JSON MIME.
   * JSON MIME examples:
   *   application/json
   *   application/json; charset=UTF8
   *   APPLICATION/JSON
   *   application/vnd.company+json
   * @param mime - MIME (Multipurpose Internet Mail Extensions)
   * @return True if the given MIME is JSON, false otherwise.
   */
  isJsonMime(mime) {
    if (!mime) {
      return false;
    }
    return _BaseAPI.jsonRegex.test(mime);
  }
  async request(context, initOverrides) {
    const { url, init } = await this.createFetchParams(context, initOverrides);
    const response = await this.fetchApi(url, init);
    if (response && (response.status >= 200 && response.status < 300)) {
      return response;
    }
    throw new ResponseError(response, "Response returned an error code");
  }
  async createFetchParams(context, initOverrides) {
    let url = this.configuration.basePath + context.path;
    if (context.query !== void 0 && Object.keys(context.query).length !== 0) {
      url += "?" + this.configuration.queryParamsStringify(context.query);
    }
    const headers = Object.assign({}, this.configuration.headers, context.headers);
    Object.keys(headers).forEach((key) => headers[key] === void 0 ? delete headers[key] : {});
    const initOverrideFn = typeof initOverrides === "function" ? initOverrides : async () => initOverrides;
    const initParams = {
      method: context.method,
      headers,
      body: context.body,
      credentials: this.configuration.credentials
    };
    const overriddenInit = {
      ...initParams,
      ...await initOverrideFn({
        init: initParams,
        context
      })
    };
    let body;
    if (isFormData(overriddenInit.body) || overriddenInit.body instanceof URLSearchParams || isBlob(overriddenInit.body)) {
      body = overriddenInit.body;
    } else if (this.isJsonMime(headers["Content-Type"])) {
      body = JSON.stringify(overriddenInit.body);
    } else {
      body = overriddenInit.body;
    }
    const init = {
      ...overriddenInit,
      body
    };
    return { url, init };
  }
  fetchApi = async (url, init) => {
    let fetchParams = { url, init };
    for (const middleware of this.middleware) {
      if (middleware.pre) {
        fetchParams = await middleware.pre({
          fetch: this.fetchApi,
          ...fetchParams
        }) || fetchParams;
      }
    }
    let response = void 0;
    try {
      response = await (this.configuration.fetchApi || fetch)(fetchParams.url, fetchParams.init);
    } catch (e) {
      for (const middleware of this.middleware) {
        if (middleware.onError) {
          response = await middleware.onError({
            fetch: this.fetchApi,
            url: fetchParams.url,
            init: fetchParams.init,
            error: e,
            response: response ? response.clone() : void 0
          }) || response;
        }
      }
      if (response === void 0) {
        if (e instanceof Error) {
          throw new FetchError(e, "The request failed and the interceptors did not return an alternative response");
        } else {
          throw e;
        }
      }
    }
    for (const middleware of this.middleware) {
      if (middleware.post) {
        response = await middleware.post({
          fetch: this.fetchApi,
          url: fetchParams.url,
          init: fetchParams.init,
          response: response.clone()
        }) || response;
      }
    }
    return response;
  };
  /**
   * Create a shallow clone of `this` by constructing a new instance
   * and then shallow cloning data members.
   */
  clone() {
    const constructor = this.constructor;
    const next = new constructor(this.configuration);
    next.middleware = this.middleware.slice();
    return next;
  }
};
function isBlob(value) {
  return typeof Blob !== "undefined" && value instanceof Blob;
}
function isFormData(value) {
  return typeof FormData !== "undefined" && value instanceof FormData;
}
var ResponseError = class extends Error {
  constructor(response, msg) {
    super(msg);
    this.response = response;
  }
  name = "ResponseError";
};
var FetchError = class extends Error {
  constructor(cause, msg) {
    super(msg);
    this.cause = cause;
  }
  name = "FetchError";
};
var RequiredError = class extends Error {
  constructor(field, msg) {
    super(msg);
    this.field = field;
  }
  name = "RequiredError";
};
var COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "	",
  pipes: "|"
};
function exists(json, key) {
  const value = json[key];
  return value !== null && value !== void 0;
}
function querystring(params, prefix = "") {
  return Object.keys(params).map((key) => querystringSingleKey(key, params[key], prefix)).filter((part) => part.length > 0).join("&");
}
function querystringSingleKey(key, value, keyPrefix = "") {
  const fullKey = keyPrefix + (keyPrefix.length ? `[${key}]` : key);
  if (value instanceof Array) {
    const multiValue = value.map((singleValue) => encodeURIComponent(String(singleValue))).join(`&${encodeURIComponent(fullKey)}=`);
    return `${encodeURIComponent(fullKey)}=${multiValue}`;
  }
  if (value instanceof Set) {
    const valueAsArray = Array.from(value);
    return querystringSingleKey(key, valueAsArray, keyPrefix);
  }
  if (value instanceof Date) {
    return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value.toISOString())}`;
  }
  if (value instanceof Object) {
    return querystring(value, fullKey);
  }
  return `${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value))}`;
}
function mapValues(data, fn) {
  return Object.keys(data).reduce(
    (acc, key) => ({ ...acc, [key]: fn(data[key]) }),
    {}
  );
}
function canConsumeForm(consumes) {
  for (const consume of consumes) {
    if ("multipart/form-data" === consume.contentType) {
      return true;
    }
  }
  return false;
}
var JSONApiResponse = class {
  constructor(raw, transformer = (jsonValue) => jsonValue) {
    this.raw = raw;
    this.transformer = transformer;
  }
  async value() {
    return this.transformer(await this.raw.json());
  }
};
var VoidApiResponse = class {
  constructor(raw) {
    this.raw = raw;
  }
  async value() {
    return void 0;
  }
};
var BlobApiResponse = class {
  constructor(raw) {
    this.raw = raw;
  }
  async value() {
    return await this.raw.blob();
  }
};
var TextApiResponse = class {
  constructor(raw) {
    this.raw = raw;
  }
  async value() {
    return await this.raw.text();
  }
};

// src/generated/orchestrators/plans/models/ModelsAcceptSubscriptionAddress.ts
function instanceOfModelsAcceptSubscriptionAddress(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsAcceptSubscriptionAddressFromJSON(json) {
  return ModelsAcceptSubscriptionAddressFromJSONTyped(json);
}
function ModelsAcceptSubscriptionAddressFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "id": !exists(json, "id") ? void 0 : json["id"]
  };
}
function ModelsAcceptSubscriptionAddressToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "id": value.id
  };
}

// src/generated/orchestrators/plans/models/ModelsAcceptSubscriptionCustomerData.ts
function instanceOfModelsAcceptSubscriptionCustomerData(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsAcceptSubscriptionCustomerDataFromJSON(json) {
  return ModelsAcceptSubscriptionCustomerDataFromJSONTyped(json);
}
function ModelsAcceptSubscriptionCustomerDataFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "birthDate": !exists(json, "birth_date") ? void 0 : new Date(json["birth_date"]),
    "homeAddress": !exists(json, "home_address") ? void 0 : ModelsAcceptSubscriptionAddressFromJSON(json["home_address"]),
    "mobileNumber": !exists(json, "mobile_number") ? void 0 : json["mobile_number"]
  };
}
function ModelsAcceptSubscriptionCustomerDataToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "birth_date": value.birthDate === void 0 ? void 0 : value.birthDate.toISOString().substring(0, 10),
    "home_address": ModelsAcceptSubscriptionAddressToJSON(value.homeAddress),
    "mobile_number": value.mobileNumber
  };
}

// src/generated/orchestrators/plans/models/ModelsAcceptSubscriptionInviteRequest.ts
function instanceOfModelsAcceptSubscriptionInviteRequest(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsAcceptSubscriptionInviteRequestFromJSON(json) {
  return ModelsAcceptSubscriptionInviteRequestFromJSONTyped(json);
}
function ModelsAcceptSubscriptionInviteRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "customer": !exists(json, "customer") ? void 0 : ModelsAcceptSubscriptionCustomerDataFromJSON(json["customer"]),
    "customerPatientRelationship": !exists(json, "customer_patient_relationship") ? void 0 : json["customer_patient_relationship"]
  };
}
function ModelsAcceptSubscriptionInviteRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "customer": ModelsAcceptSubscriptionCustomerDataToJSON(value.customer),
    "customer_patient_relationship": value.customerPatientRelationship
  };
}

// src/generated/orchestrators/plans/models/ModelsAccountLink.ts
function instanceOfModelsAccountLink(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsAccountLinkFromJSON(json) {
  return ModelsAccountLinkFromJSONTyped(json);
}
function ModelsAccountLinkFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "url": !exists(json, "url") ? void 0 : json["url"]
  };
}
function ModelsAccountLinkToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "url": value.url
  };
}

// src/generated/orchestrators/plans/models/ModelsApplyPlansSubscriptionRequest.ts
function instanceOfModelsApplyPlansSubscriptionRequest(value) {
  let isInstance = true;
  isInstance = isInstance && "email" in value;
  return isInstance;
}
function ModelsApplyPlansSubscriptionRequestFromJSON(json) {
  return ModelsApplyPlansSubscriptionRequestFromJSONTyped(json);
}
function ModelsApplyPlansSubscriptionRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "email": json["email"]
  };
}
function ModelsApplyPlansSubscriptionRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "email": value.email
  };
}

// src/generated/orchestrators/plans/models/ModelsApplyPlansSubscriptionResponse.ts
function instanceOfModelsApplyPlansSubscriptionResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsApplyPlansSubscriptionResponseFromJSON(json) {
  return ModelsApplyPlansSubscriptionResponseFromJSONTyped(json);
}
function ModelsApplyPlansSubscriptionResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "inviteToken": !exists(json, "invite_token") ? void 0 : json["invite_token"],
    "subscriptionDisplayId": !exists(json, "subscription_display_id") ? void 0 : json["subscription_display_id"],
    "subscriptionId": !exists(json, "subscription_id") ? void 0 : json["subscription_id"]
  };
}
function ModelsApplyPlansSubscriptionResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "invite_token": value.inviteToken,
    "subscription_display_id": value.subscriptionDisplayId,
    "subscription_id": value.subscriptionId
  };
}

// src/generated/orchestrators/plans/models/ModelsBankAccount.ts
function instanceOfModelsBankAccount(value) {
  let isInstance = true;
  isInstance = isInstance && "accountHolderName" in value;
  isInstance = isInstance && "accountNumberEnding" in value;
  isInstance = isInstance && "bankName" in value;
  isInstance = isInstance && "countryCode" in value;
  isInstance = isInstance && "createdAt" in value;
  isInstance = isInstance && "id" in value;
  return isInstance;
}
function ModelsBankAccountFromJSON(json) {
  return ModelsBankAccountFromJSONTyped(json);
}
function ModelsBankAccountFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "accountHolderName": json["account_holder_name"],
    "accountNumberEnding": json["account_number_ending"],
    "bankName": json["bank_name"],
    "countryCode": json["country_code"],
    "createdAt": json["created_at"],
    "id": json["id"]
  };
}
function ModelsBankAccountToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "account_holder_name": value.accountHolderName,
    "account_number_ending": value.accountNumberEnding,
    "bank_name": value.bankName,
    "country_code": value.countryCode,
    "created_at": value.createdAt,
    "id": value.id
  };
}

// src/generated/orchestrators/plans/models/ModelsCancelSubscriptionResponse.ts
function instanceOfModelsCancelSubscriptionResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsCancelSubscriptionResponseFromJSON(json) {
  return ModelsCancelSubscriptionResponseFromJSONTyped(json);
}
function ModelsCancelSubscriptionResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "id": !exists(json, "id") ? void 0 : json["id"],
    "newStatus": !exists(json, "new_status") ? void 0 : json["new_status"]
  };
}
function ModelsCancelSubscriptionResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "id": value.id,
    "new_status": value.newStatus
  };
}

// src/generated/orchestrators/plans/models/ModelsCapabilities.ts
function instanceOfModelsCapabilities(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsCapabilitiesFromJSON(json) {
  return ModelsCapabilitiesFromJSONTyped(json);
}
function ModelsCapabilitiesFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "bacsDebitPayments": !exists(json, "bacs_debit_payments") ? void 0 : json["bacs_debit_payments"],
    "cardPayments": !exists(json, "card_payments") ? void 0 : json["card_payments"],
    "transfers": !exists(json, "transfers") ? void 0 : json["transfers"]
  };
}
function ModelsCapabilitiesToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "bacs_debit_payments": value.bacsDebitPayments,
    "card_payments": value.cardPayments,
    "transfers": value.transfers
  };
}

// src/generated/orchestrators/plans/models/ModelsChangePlanStatusRequest.ts
function instanceOfModelsChangePlanStatusRequest(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsChangePlanStatusRequestFromJSON(json) {
  return ModelsChangePlanStatusRequestFromJSONTyped(json);
}
function ModelsChangePlanStatusRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "newStatusCode": !exists(json, "new_status_code") ? void 0 : json["new_status_code"]
  };
}
function ModelsChangePlanStatusRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "new_status_code": value.newStatusCode
  };
}

// src/generated/orchestrators/plans/models/ModelsPerk.ts
function instanceOfModelsPerk(value) {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  return isInstance;
}
function ModelsPerkFromJSON(json) {
  return ModelsPerkFromJSONTyped(json);
}
function ModelsPerkFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "id": !exists(json, "id") ? void 0 : json["id"],
    "name": json["name"],
    "quantity": !exists(json, "quantity") ? void 0 : json["quantity"]
  };
}
function ModelsPerkToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "id": value.id,
    "name": value.name,
    "quantity": value.quantity
  };
}

// src/generated/orchestrators/plans/models/ModelsTreatment.ts
function instanceOfModelsTreatment(value) {
  let isInstance = true;
  isInstance = isInstance && "legacyId" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "quantity" in value;
  return isInstance;
}
function ModelsTreatmentFromJSON(json) {
  return ModelsTreatmentFromJSONTyped(json);
}
function ModelsTreatmentFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "category": !exists(json, "category") ? void 0 : json["category"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "legacyId": json["legacy_id"],
    "name": json["name"],
    "quantity": json["quantity"]
  };
}
function ModelsTreatmentToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "category": value.category,
    "id": value.id,
    "legacy_id": value.legacyId,
    "name": value.name,
    "quantity": value.quantity
  };
}

// src/generated/orchestrators/plans/models/ModelsPlan.ts
function instanceOfModelsPlan(value) {
  let isInstance = true;
  isInstance = isInstance && "createdAt" in value;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "perks" in value;
  isInstance = isInstance && "price" in value;
  isInstance = isInstance && "_private" in value;
  isInstance = isInstance && "slug" in value;
  isInstance = isInstance && "treatments" in value;
  isInstance = isInstance && "updatedAt" in value;
  isInstance = isInstance && "vendorId" in value;
  return isInstance;
}
function ModelsPlanFromJSON(json) {
  return ModelsPlanFromJSONTyped(json);
}
function ModelsPlanFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "createdAt": json["created_at"],
    "currency": json["currency"],
    "description": json["description"],
    "displayId": !exists(json, "display_id") ? void 0 : json["display_id"],
    "excludedGoodsServices": !exists(json, "excluded_goods_services") ? void 0 : json["excluded_goods_services"],
    "hasActiveSubscriptions": !exists(json, "has_active_subscriptions") ? void 0 : json["has_active_subscriptions"],
    "id": json["id"],
    "imageUrl": !exists(json, "image_url") ? void 0 : json["image_url"],
    "internalDescription": !exists(json, "internal_description") ? void 0 : json["internal_description"],
    "internalReference": !exists(json, "internal_reference") ? void 0 : json["internal_reference"],
    "isDeletable": !exists(json, "is_deletable") ? void 0 : json["is_deletable"],
    "name": json["name"],
    "perks": json["perks"].map(ModelsPerkFromJSON),
    "price": json["price"],
    "_private": json["private"],
    "slug": json["slug"],
    "status": !exists(json, "status") ? void 0 : json["status"],
    "treatments": json["treatments"].map(ModelsTreatmentFromJSON),
    "updatedAt": json["updated_at"],
    "vendorId": json["vendor_id"]
  };
}
function ModelsPlanToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "created_at": value.createdAt,
    "currency": value.currency,
    "description": value.description,
    "display_id": value.displayId,
    "excluded_goods_services": value.excludedGoodsServices,
    "has_active_subscriptions": value.hasActiveSubscriptions,
    "id": value.id,
    "image_url": value.imageUrl,
    "internal_description": value.internalDescription,
    "internal_reference": value.internalReference,
    "is_deletable": value.isDeletable,
    "name": value.name,
    "perks": value.perks.map(ModelsPerkToJSON),
    "price": value.price,
    "private": value._private,
    "slug": value.slug,
    "status": value.status,
    "treatments": value.treatments.map(ModelsTreatmentToJSON),
    "updated_at": value.updatedAt,
    "vendor_id": value.vendorId
  };
}

// src/generated/orchestrators/plans/models/ModelsChangePlanStatusResponse.ts
function instanceOfModelsChangePlanStatusResponse(value) {
  let isInstance = true;
  isInstance = isInstance && "plan" in value;
  return isInstance;
}
function ModelsChangePlanStatusResponseFromJSON(json) {
  return ModelsChangePlanStatusResponseFromJSONTyped(json);
}
function ModelsChangePlanStatusResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "plan": ModelsPlanFromJSON(json["plan"])
  };
}
function ModelsChangePlanStatusResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "plan": ModelsPlanToJSON(value.plan)
  };
}

// src/generated/orchestrators/plans/models/ModelsChangeSubscriptionClinicianRequest.ts
function instanceOfModelsChangeSubscriptionClinicianRequest(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsChangeSubscriptionClinicianRequestFromJSON(json) {
  return ModelsChangeSubscriptionClinicianRequestFromJSONTyped(json);
}
function ModelsChangeSubscriptionClinicianRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "clinicianId": !exists(json, "clinician_id") ? void 0 : json["clinician_id"]
  };
}
function ModelsChangeSubscriptionClinicianRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "clinician_id": value.clinicianId
  };
}

// src/generated/orchestrators/plans/models/ModelsChangeSubscriptionStatusRequest.ts
function instanceOfModelsChangeSubscriptionStatusRequest(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsChangeSubscriptionStatusRequestFromJSON(json) {
  return ModelsChangeSubscriptionStatusRequestFromJSONTyped(json);
}
function ModelsChangeSubscriptionStatusRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "newStatusCode": !exists(json, "new_status_code") ? void 0 : json["new_status_code"]
  };
}
function ModelsChangeSubscriptionStatusRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "new_status_code": value.newStatusCode
  };
}

// src/generated/orchestrators/plans/models/ModelsClaim.ts
function instanceOfModelsClaim(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsClaimFromJSON(json) {
  return ModelsClaimFromJSONTyped(json);
}
function ModelsClaimFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "claimDate": !exists(json, "claim_date") ? void 0 : json["claim_date"],
    "clinician": !exists(json, "clinician") ? void 0 : json["clinician"],
    "id": !exists(json, "id") ? void 0 : json["id"]
  };
}
function ModelsClaimToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "claim_date": value.claimDate,
    "clinician": value.clinician,
    "id": value.id
  };
}

// src/generated/orchestrators/plans/models/ModelsClinician.ts
function instanceOfModelsClinician(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsClinicianFromJSON(json) {
  return ModelsClinicianFromJSONTyped(json);
}
function ModelsClinicianFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "displayId": !exists(json, "display_id") ? void 0 : json["display_id"],
    "email": !exists(json, "email") ? void 0 : json["email"],
    "firstName": !exists(json, "first_name") ? void 0 : json["first_name"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "lastName": !exists(json, "last_name") ? void 0 : json["last_name"],
    "legacyId": !exists(json, "legacy_id") ? void 0 : json["legacy_id"],
    "phone": !exists(json, "phone") ? void 0 : json["phone"],
    "role": !exists(json, "role") ? void 0 : json["role"],
    "status": !exists(json, "status") ? void 0 : json["status"]
  };
}
function ModelsClinicianToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "display_id": value.displayId,
    "email": value.email,
    "first_name": value.firstName,
    "id": value.id,
    "last_name": value.lastName,
    "legacy_id": value.legacyId,
    "phone": value.phone,
    "role": value.role,
    "status": value.status
  };
}

// src/generated/orchestrators/plans/models/ModelsConnectedAccount.ts
function instanceOfModelsConnectedAccount(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsConnectedAccountFromJSON(json) {
  return ModelsConnectedAccountFromJSONTyped(json);
}
function ModelsConnectedAccountFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "capabilities": !exists(json, "capabilities") ? void 0 : ModelsCapabilitiesFromJSON(json["capabilities"]),
    "id": !exists(json, "id") ? void 0 : json["id"],
    "status": !exists(json, "status") ? void 0 : json["status"]
  };
}
function ModelsConnectedAccountToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "capabilities": ModelsCapabilitiesToJSON(value.capabilities),
    "id": value.id,
    "status": value.status
  };
}

// src/generated/orchestrators/plans/models/ModelsCreateDirectDebitSubscriptionRequest.ts
function instanceOfModelsCreateDirectDebitSubscriptionRequest(value) {
  let isInstance = true;
  isInstance = isInstance && "bankAccountId" in value;
  isInstance = isInstance && "membershipId" in value;
  return isInstance;
}
function ModelsCreateDirectDebitSubscriptionRequestFromJSON(json) {
  return ModelsCreateDirectDebitSubscriptionRequestFromJSONTyped(json);
}
function ModelsCreateDirectDebitSubscriptionRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "bankAccountId": json["bank_account_id"],
    "membershipId": json["membership_id"]
  };
}
function ModelsCreateDirectDebitSubscriptionRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "bank_account_id": value.bankAccountId,
    "membership_id": value.membershipId
  };
}

// src/generated/orchestrators/plans/models/ModelsCreateFeatureFlagRequest.ts
function instanceOfModelsCreateFeatureFlagRequest(value) {
  let isInstance = true;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "key" in value;
  isInstance = isInstance && "name" in value;
  return isInstance;
}
function ModelsCreateFeatureFlagRequestFromJSON(json) {
  return ModelsCreateFeatureFlagRequestFromJSONTyped(json);
}
function ModelsCreateFeatureFlagRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "defaultValue": !exists(json, "default_value") ? void 0 : json["default_value"],
    "description": json["description"],
    "key": json["key"],
    "name": json["name"]
  };
}
function ModelsCreateFeatureFlagRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "default_value": value.defaultValue,
    "description": value.description,
    "key": value.key,
    "name": value.name
  };
}

// src/generated/orchestrators/plans/models/ModelsCreateInvitePlansSubscriptionRequest.ts
function instanceOfModelsCreateInvitePlansSubscriptionRequest(value) {
  let isInstance = true;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "firstName" in value;
  isInstance = isInstance && "gender" in value;
  isInstance = isInstance && "lastName" in value;
  return isInstance;
}
function ModelsCreateInvitePlansSubscriptionRequestFromJSON(json) {
  return ModelsCreateInvitePlansSubscriptionRequestFromJSONTyped(json);
}
function ModelsCreateInvitePlansSubscriptionRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "clinicianLegacyId": !exists(json, "clinician_legacy_id") ? void 0 : json["clinician_legacy_id"],
    "email": json["email"],
    "firstName": json["first_name"],
    "gender": json["gender"],
    "lastName": json["last_name"]
  };
}
function ModelsCreateInvitePlansSubscriptionRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "clinician_legacy_id": value.clinicianLegacyId,
    "email": value.email,
    "first_name": value.firstName,
    "gender": value.gender,
    "last_name": value.lastName
  };
}

// src/generated/orchestrators/plans/models/ModelsCreateInvitePlansSubscriptionResponse.ts
function instanceOfModelsCreateInvitePlansSubscriptionResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsCreateInvitePlansSubscriptionResponseFromJSON(json) {
  return ModelsCreateInvitePlansSubscriptionResponseFromJSONTyped(json);
}
function ModelsCreateInvitePlansSubscriptionResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "displayId": !exists(json, "display_id") ? void 0 : json["display_id"],
    "id": !exists(json, "id") ? void 0 : json["id"]
  };
}
function ModelsCreateInvitePlansSubscriptionResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "display_id": value.displayId,
    "id": value.id
  };
}

// src/generated/orchestrators/plans/models/ModelsCreatePerkParams.ts
function instanceOfModelsCreatePerkParams(value) {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  return isInstance;
}
function ModelsCreatePerkParamsFromJSON(json) {
  return ModelsCreatePerkParamsFromJSONTyped(json);
}
function ModelsCreatePerkParamsFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "name": json["name"],
    "quantity": !exists(json, "quantity") ? void 0 : json["quantity"]
  };
}
function ModelsCreatePerkParamsToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "name": value.name,
    "quantity": value.quantity
  };
}

// src/generated/orchestrators/plans/models/ModelsCreateTreatmentParams.ts
function instanceOfModelsCreateTreatmentParams(value) {
  let isInstance = true;
  isInstance = isInstance && "legacyId" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "quantity" in value;
  return isInstance;
}
function ModelsCreateTreatmentParamsFromJSON(json) {
  return ModelsCreateTreatmentParamsFromJSONTyped(json);
}
function ModelsCreateTreatmentParamsFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "category": !exists(json, "category") ? void 0 : json["category"],
    "legacyId": json["legacy_id"],
    "name": json["name"],
    "quantity": json["quantity"]
  };
}
function ModelsCreateTreatmentParamsToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "category": value.category,
    "legacy_id": value.legacyId,
    "name": value.name,
    "quantity": value.quantity
  };
}

// src/generated/orchestrators/plans/models/ModelsCreatePlanRequest.ts
function instanceOfModelsCreatePlanRequest(value) {
  let isInstance = true;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "price" in value;
  isInstance = isInstance && "vendorId" in value;
  return isInstance;
}
function ModelsCreatePlanRequestFromJSON(json) {
  return ModelsCreatePlanRequestFromJSONTyped(json);
}
function ModelsCreatePlanRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "currency": !exists(json, "currency") ? void 0 : json["currency"],
    "description": json["description"],
    "excludedGoodsServices": !exists(json, "excluded_goods_services") ? void 0 : json["excluded_goods_services"],
    "imageUrl": !exists(json, "image_url") ? void 0 : json["image_url"],
    "internalDescription": !exists(json, "internal_description") ? void 0 : json["internal_description"],
    "internalReference": !exists(json, "internal_reference") ? void 0 : json["internal_reference"],
    "name": json["name"],
    "perks": !exists(json, "perks") ? void 0 : json["perks"].map(ModelsCreatePerkParamsFromJSON),
    "price": json["price"],
    "_private": !exists(json, "private") ? void 0 : json["private"],
    "treatments": !exists(json, "treatments") ? void 0 : json["treatments"].map(ModelsCreateTreatmentParamsFromJSON),
    "vendorId": json["vendor_id"]
  };
}
function ModelsCreatePlanRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "currency": value.currency,
    "description": value.description,
    "excluded_goods_services": value.excludedGoodsServices,
    "image_url": value.imageUrl,
    "internal_description": value.internalDescription,
    "internal_reference": value.internalReference,
    "name": value.name,
    "perks": value.perks === void 0 ? void 0 : value.perks.map(ModelsCreatePerkParamsToJSON),
    "price": value.price,
    "private": value._private,
    "treatments": value.treatments === void 0 ? void 0 : value.treatments.map(ModelsCreateTreatmentParamsToJSON),
    "vendor_id": value.vendorId
  };
}

// src/generated/orchestrators/plans/models/ModelsCreatePlanResponse.ts
function instanceOfModelsCreatePlanResponse(value) {
  let isInstance = true;
  isInstance = isInstance && "plan" in value;
  return isInstance;
}
function ModelsCreatePlanResponseFromJSON(json) {
  return ModelsCreatePlanResponseFromJSONTyped(json);
}
function ModelsCreatePlanResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "plan": ModelsPlanFromJSON(json["plan"])
  };
}
function ModelsCreatePlanResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "plan": ModelsPlanToJSON(value.plan)
  };
}

// src/generated/orchestrators/plans/models/ModelsCreateStripeSubscriptionRequest.ts
function instanceOfModelsCreateStripeSubscriptionRequest(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsCreateStripeSubscriptionRequestFromJSON(json) {
  return ModelsCreateStripeSubscriptionRequestFromJSONTyped(json);
}
function ModelsCreateStripeSubscriptionRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "membershipId": !exists(json, "membership_id") ? void 0 : json["membership_id"]
  };
}
function ModelsCreateStripeSubscriptionRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "membership_id": value.membershipId
  };
}

// src/generated/orchestrators/plans/models/ModelsDirectDebitCustomer.ts
function instanceOfModelsDirectDebitCustomer(value) {
  let isInstance = true;
  isInstance = isInstance && "countryCode" in value;
  isInstance = isInstance && "createdAt" in value;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "familyName" in value;
  isInstance = isInstance && "givenName" in value;
  isInstance = isInstance && "id" in value;
  return isInstance;
}
function ModelsDirectDebitCustomerFromJSON(json) {
  return ModelsDirectDebitCustomerFromJSONTyped(json);
}
function ModelsDirectDebitCustomerFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "countryCode": json["country_code"],
    "createdAt": json["created_at"],
    "email": json["email"],
    "familyName": json["family_name"],
    "givenName": json["given_name"],
    "id": json["id"]
  };
}
function ModelsDirectDebitCustomerToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "country_code": value.countryCode,
    "created_at": value.createdAt,
    "email": value.email,
    "family_name": value.familyName,
    "given_name": value.givenName,
    "id": value.id
  };
}

// src/generated/orchestrators/plans/models/ModelsDirectDebitSubscription.ts
function instanceOfModelsDirectDebitSubscription(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsDirectDebitSubscriptionFromJSON(json) {
  return ModelsDirectDebitSubscriptionFromJSONTyped(json);
}
function ModelsDirectDebitSubscriptionFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "amount": !exists(json, "amount") ? void 0 : json["amount"],
    "createdAt": !exists(json, "created_at") ? void 0 : json["created_at"],
    "currency": !exists(json, "currency") ? void 0 : json["currency"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "intervalUnit": !exists(json, "interval_unit") ? void 0 : json["interval_unit"],
    "mandateId": !exists(json, "mandate_id") ? void 0 : json["mandate_id"],
    "name": !exists(json, "name") ? void 0 : json["name"]
  };
}
function ModelsDirectDebitSubscriptionToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "amount": value.amount,
    "created_at": value.createdAt,
    "currency": value.currency,
    "id": value.id,
    "interval_unit": value.intervalUnit,
    "mandate_id": value.mandateId,
    "name": value.name
  };
}

// src/generated/orchestrators/plans/models/ModelsFeatureFlag.ts
function instanceOfModelsFeatureFlag(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsFeatureFlagFromJSON(json) {
  return ModelsFeatureFlagFromJSONTyped(json);
}
function ModelsFeatureFlagFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "defaultValue": !exists(json, "default_value") ? void 0 : json["default_value"],
    "description": !exists(json, "description") ? void 0 : json["description"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "key": !exists(json, "key") ? void 0 : json["key"],
    "name": !exists(json, "name") ? void 0 : json["name"]
  };
}
function ModelsFeatureFlagToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "default_value": value.defaultValue,
    "description": value.description,
    "id": value.id,
    "key": value.key,
    "name": value.name
  };
}

// src/generated/orchestrators/plans/models/ModelsGetInviteCustomer.ts
function instanceOfModelsGetInviteCustomer(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetInviteCustomerFromJSON(json) {
  return ModelsGetInviteCustomerFromJSONTyped(json);
}
function ModelsGetInviteCustomerFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "email": !exists(json, "email") ? void 0 : json["email"],
    "firstName": !exists(json, "first_name") ? void 0 : json["first_name"],
    "gender": !exists(json, "gender") ? void 0 : json["gender"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "lastName": !exists(json, "last_name") ? void 0 : json["last_name"],
    "legacyId": !exists(json, "legacy_id") ? void 0 : json["legacy_id"]
  };
}
function ModelsGetInviteCustomerToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "email": value.email,
    "first_name": value.firstName,
    "gender": value.gender,
    "id": value.id,
    "last_name": value.lastName,
    "legacy_id": value.legacyId
  };
}

// src/generated/orchestrators/plans/models/ModelsGetInviteDocument.ts
function instanceOfModelsGetInviteDocument(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetInviteDocumentFromJSON(json) {
  return ModelsGetInviteDocumentFromJSONTyped(json);
}
function ModelsGetInviteDocumentFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "name": !exists(json, "name") ? void 0 : json["name"],
    "url": !exists(json, "url") ? void 0 : json["url"]
  };
}
function ModelsGetInviteDocumentToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "name": value.name,
    "url": value.url
  };
}

// src/generated/orchestrators/plans/models/ModelsGetInvitePerk.ts
function instanceOfModelsGetInvitePerk(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetInvitePerkFromJSON(json) {
  return ModelsGetInvitePerkFromJSONTyped(json);
}
function ModelsGetInvitePerkFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "name": !exists(json, "name") ? void 0 : json["name"],
    "quantity": !exists(json, "quantity") ? void 0 : json["quantity"]
  };
}
function ModelsGetInvitePerkToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "name": value.name,
    "quantity": value.quantity
  };
}

// src/generated/orchestrators/plans/models/ModelsGetInviteTreatment.ts
function instanceOfModelsGetInviteTreatment(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetInviteTreatmentFromJSON(json) {
  return ModelsGetInviteTreatmentFromJSONTyped(json);
}
function ModelsGetInviteTreatmentFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "name": !exists(json, "name") ? void 0 : json["name"],
    "quantity": !exists(json, "quantity") ? void 0 : json["quantity"]
  };
}
function ModelsGetInviteTreatmentToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "name": value.name,
    "quantity": value.quantity
  };
}

// src/generated/orchestrators/plans/models/ModelsGetInvitePlan.ts
function instanceOfModelsGetInvitePlan(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetInvitePlanFromJSON(json) {
  return ModelsGetInvitePlanFromJSONTyped(json);
}
function ModelsGetInvitePlanFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "currency": !exists(json, "currency") ? void 0 : json["currency"],
    "description": !exists(json, "description") ? void 0 : json["description"],
    "excludedGoodsServices": !exists(json, "excluded_goods_services") ? void 0 : json["excluded_goods_services"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "imageUrl": !exists(json, "image_url") ? void 0 : json["image_url"],
    "name": !exists(json, "name") ? void 0 : json["name"],
    "perks": !exists(json, "perks") ? void 0 : json["perks"].map(ModelsGetInvitePerkFromJSON),
    "price": !exists(json, "price") ? void 0 : json["price"],
    "slug": !exists(json, "slug") ? void 0 : json["slug"],
    "treatments": !exists(json, "treatments") ? void 0 : json["treatments"].map(ModelsGetInviteTreatmentFromJSON)
  };
}
function ModelsGetInvitePlanToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "currency": value.currency,
    "description": value.description,
    "excluded_goods_services": value.excludedGoodsServices,
    "id": value.id,
    "image_url": value.imageUrl,
    "name": value.name,
    "perks": value.perks === void 0 ? void 0 : value.perks.map(ModelsGetInvitePerkToJSON),
    "price": value.price,
    "slug": value.slug,
    "treatments": value.treatments === void 0 ? void 0 : value.treatments.map(ModelsGetInviteTreatmentToJSON)
  };
}

// src/generated/orchestrators/plans/models/ModelsGetInviteStatus.ts
function instanceOfModelsGetInviteStatus(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetInviteStatusFromJSON(json) {
  return ModelsGetInviteStatusFromJSONTyped(json);
}
function ModelsGetInviteStatusFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "bankDetails": !exists(json, "bank_details") ? void 0 : json["bank_details"],
    "customerDetails": !exists(json, "customer_details") ? void 0 : json["customer_details"],
    "patientDetails": !exists(json, "patient_details") ? void 0 : json["patient_details"],
    "registered": !exists(json, "registered") ? void 0 : json["registered"],
    "status": !exists(json, "status") ? void 0 : json["status"]
  };
}
function ModelsGetInviteStatusToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "bank_details": value.bankDetails,
    "customer_details": value.customerDetails,
    "patient_details": value.patientDetails,
    "registered": value.registered,
    "status": value.status
  };
}

// src/generated/orchestrators/plans/models/ModelsGetInviteSubscription.ts
function instanceOfModelsGetInviteSubscription(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetInviteSubscriptionFromJSON(json) {
  return ModelsGetInviteSubscriptionFromJSONTyped(json);
}
function ModelsGetInviteSubscriptionFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "id": !exists(json, "id") ? void 0 : json["id"]
  };
}
function ModelsGetInviteSubscriptionToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "id": value.id
  };
}

// src/generated/orchestrators/plans/models/ModelsGetInviteVendor.ts
function instanceOfModelsGetInviteVendor(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetInviteVendorFromJSON(json) {
  return ModelsGetInviteVendorFromJSONTyped(json);
}
function ModelsGetInviteVendorFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "directDebitProvider": !exists(json, "direct_debit_provider") ? void 0 : json["direct_debit_provider"],
    "email": !exists(json, "email") ? void 0 : json["email"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "legalForm": !exists(json, "legal_form") ? void 0 : json["legal_form"],
    "legalName": !exists(json, "legal_name") ? void 0 : json["legal_name"],
    "logoUrl": !exists(json, "logo_url") ? void 0 : json["logo_url"],
    "phoneNumber": !exists(json, "phone_number") ? void 0 : json["phone_number"],
    "primaryColor": !exists(json, "primary_color") ? void 0 : json["primary_color"],
    "slug": !exists(json, "slug") ? void 0 : json["slug"],
    "tradingName": !exists(json, "trading_name") ? void 0 : json["trading_name"]
  };
}
function ModelsGetInviteVendorToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "direct_debit_provider": value.directDebitProvider,
    "email": value.email,
    "id": value.id,
    "legal_form": value.legalForm,
    "legal_name": value.legalName,
    "logo_url": value.logoUrl,
    "phone_number": value.phoneNumber,
    "primary_color": value.primaryColor,
    "slug": value.slug,
    "trading_name": value.tradingName
  };
}

// src/generated/orchestrators/plans/models/ModelsGetPlanPublicResponse.ts
function instanceOfModelsGetPlanPublicResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetPlanPublicResponseFromJSON(json) {
  return ModelsGetPlanPublicResponseFromJSONTyped(json);
}
function ModelsGetPlanPublicResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "plan": !exists(json, "plan") ? void 0 : ModelsGetInvitePlanFromJSON(json["plan"]),
    "vendor": !exists(json, "vendor") ? void 0 : ModelsGetInviteVendorFromJSON(json["vendor"])
  };
}
function ModelsGetPlanPublicResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "plan": ModelsGetInvitePlanToJSON(value.plan),
    "vendor": ModelsGetInviteVendorToJSON(value.vendor)
  };
}

// src/generated/orchestrators/plans/models/ModelsGetPlanResponse.ts
function instanceOfModelsGetPlanResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetPlanResponseFromJSON(json) {
  return ModelsGetPlanResponseFromJSONTyped(json);
}
function ModelsGetPlanResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "activeSubscriptionsNumber": !exists(json, "active_subscriptions_number") ? void 0 : json["active_subscriptions_number"],
    "plan": !exists(json, "plan") ? void 0 : ModelsPlanFromJSON(json["plan"])
  };
}
function ModelsGetPlanResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "active_subscriptions_number": value.activeSubscriptionsNumber,
    "plan": ModelsPlanToJSON(value.plan)
  };
}

// src/generated/orchestrators/plans/models/ModelsGetPlansSubscriptionInviteResponse.ts
function instanceOfModelsGetPlansSubscriptionInviteResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetPlansSubscriptionInviteResponseFromJSON(json) {
  return ModelsGetPlansSubscriptionInviteResponseFromJSONTyped(json);
}
function ModelsGetPlansSubscriptionInviteResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "customer": !exists(json, "customer") ? void 0 : ModelsGetInviteCustomerFromJSON(json["customer"]),
    "document": !exists(json, "document") ? void 0 : ModelsGetInviteDocumentFromJSON(json["document"]),
    "plan": !exists(json, "plan") ? void 0 : ModelsGetInvitePlanFromJSON(json["plan"]),
    "status": !exists(json, "status") ? void 0 : ModelsGetInviteStatusFromJSON(json["status"]),
    "subscription": !exists(json, "subscription") ? void 0 : ModelsGetInviteSubscriptionFromJSON(json["subscription"]),
    "vendor": !exists(json, "vendor") ? void 0 : ModelsGetInviteVendorFromJSON(json["vendor"])
  };
}
function ModelsGetPlansSubscriptionInviteResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "customer": ModelsGetInviteCustomerToJSON(value.customer),
    "document": ModelsGetInviteDocumentToJSON(value.document),
    "plan": ModelsGetInvitePlanToJSON(value.plan),
    "status": ModelsGetInviteStatusToJSON(value.status),
    "subscription": ModelsGetInviteSubscriptionToJSON(value.subscription),
    "vendor": ModelsGetInviteVendorToJSON(value.vendor)
  };
}

// src/generated/orchestrators/plans/models/ModelsGetSubscriptionsCustomer.ts
function instanceOfModelsGetSubscriptionsCustomer(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetSubscriptionsCustomerFromJSON(json) {
  return ModelsGetSubscriptionsCustomerFromJSONTyped(json);
}
function ModelsGetSubscriptionsCustomerFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "birthDate": !exists(json, "birth_date") ? void 0 : json["birth_date"],
    "email": !exists(json, "email") ? void 0 : json["email"],
    "firstName": !exists(json, "first_name") ? void 0 : json["first_name"],
    "homeAddress": !exists(json, "home_address") ? void 0 : json["home_address"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "lastName": !exists(json, "last_name") ? void 0 : json["last_name"],
    "mobilePhone": !exists(json, "mobile_phone") ? void 0 : json["mobile_phone"]
  };
}
function ModelsGetSubscriptionsCustomerToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "birth_date": value.birthDate,
    "email": value.email,
    "first_name": value.firstName,
    "home_address": value.homeAddress,
    "id": value.id,
    "last_name": value.lastName,
    "mobile_phone": value.mobilePhone
  };
}

// src/generated/orchestrators/plans/models/ModelsGetSubscriptionsPatient.ts
function instanceOfModelsGetSubscriptionsPatient(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetSubscriptionsPatientFromJSON(json) {
  return ModelsGetSubscriptionsPatientFromJSONTyped(json);
}
function ModelsGetSubscriptionsPatientFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "birthdate": !exists(json, "birthdate") ? void 0 : json["birthdate"],
    "firstName": !exists(json, "first_name") ? void 0 : json["first_name"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "lastName": !exists(json, "last_name") ? void 0 : json["last_name"],
    "relationship": !exists(json, "relationship") ? void 0 : json["relationship"]
  };
}
function ModelsGetSubscriptionsPatientToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "birthdate": value.birthdate,
    "first_name": value.firstName,
    "id": value.id,
    "last_name": value.lastName,
    "relationship": value.relationship
  };
}

// src/generated/orchestrators/plans/models/ModelsGetSubscriptionsPlan.ts
function instanceOfModelsGetSubscriptionsPlan(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetSubscriptionsPlanFromJSON(json) {
  return ModelsGetSubscriptionsPlanFromJSONTyped(json);
}
function ModelsGetSubscriptionsPlanFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "displayId": !exists(json, "display_id") ? void 0 : json["display_id"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "imageUrl": !exists(json, "image_url") ? void 0 : json["image_url"],
    "internalDescription": !exists(json, "internal_description") ? void 0 : json["internal_description"],
    "internalReference": !exists(json, "internal_reference") ? void 0 : json["internal_reference"],
    "name": !exists(json, "name") ? void 0 : json["name"],
    "price": !exists(json, "price") ? void 0 : json["price"]
  };
}
function ModelsGetSubscriptionsPlanToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "display_id": value.displayId,
    "id": value.id,
    "image_url": value.imageUrl,
    "internal_description": value.internalDescription,
    "internal_reference": value.internalReference,
    "name": value.name,
    "price": value.price
  };
}

// src/generated/orchestrators/plans/models/ModelsPerkUsage.ts
function instanceOfModelsPerkUsage(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsPerkUsageFromJSON(json) {
  return ModelsPerkUsageFromJSONTyped(json);
}
function ModelsPerkUsageFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "claim": !exists(json, "claim") ? void 0 : ModelsClaimFromJSON(json["claim"]),
    "quantity": !exists(json, "quantity") ? void 0 : json["quantity"]
  };
}
function ModelsPerkUsageToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "claim": ModelsClaimToJSON(value.claim),
    "quantity": value.quantity
  };
}

// src/generated/orchestrators/plans/models/ModelsUsedPerks.ts
function instanceOfModelsUsedPerks(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsUsedPerksFromJSON(json) {
  return ModelsUsedPerksFromJSONTyped(json);
}
function ModelsUsedPerksFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "perk": !exists(json, "perk") ? void 0 : ModelsPerkFromJSON(json["perk"]),
    "usages": !exists(json, "usages") ? void 0 : json["usages"].map(ModelsPerkUsageFromJSON)
  };
}
function ModelsUsedPerksToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "perk": ModelsPerkToJSON(value.perk),
    "usages": value.usages === void 0 ? void 0 : value.usages.map(ModelsPerkUsageToJSON)
  };
}

// src/generated/orchestrators/plans/models/ModelsTreatmentUsage.ts
function instanceOfModelsTreatmentUsage(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsTreatmentUsageFromJSON(json) {
  return ModelsTreatmentUsageFromJSONTyped(json);
}
function ModelsTreatmentUsageFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "claim": !exists(json, "claim") ? void 0 : ModelsClaimFromJSON(json["claim"]),
    "quantity": !exists(json, "quantity") ? void 0 : json["quantity"]
  };
}
function ModelsTreatmentUsageToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "claim": ModelsClaimToJSON(value.claim),
    "quantity": value.quantity
  };
}

// src/generated/orchestrators/plans/models/ModelsUsedTreatments.ts
function instanceOfModelsUsedTreatments(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsUsedTreatmentsFromJSON(json) {
  return ModelsUsedTreatmentsFromJSONTyped(json);
}
function ModelsUsedTreatmentsFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "treatment": !exists(json, "treatment") ? void 0 : ModelsTreatmentFromJSON(json["treatment"]),
    "usages": !exists(json, "usages") ? void 0 : json["usages"].map(ModelsTreatmentUsageFromJSON)
  };
}
function ModelsUsedTreatmentsToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "treatment": ModelsTreatmentToJSON(value.treatment),
    "usages": value.usages === void 0 ? void 0 : value.usages.map(ModelsTreatmentUsageToJSON)
  };
}

// src/generated/orchestrators/plans/models/ModelsGetSubscriptionsSubscription.ts
function instanceOfModelsGetSubscriptionsSubscription(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetSubscriptionsSubscriptionFromJSON(json) {
  return ModelsGetSubscriptionsSubscriptionFromJSONTyped(json);
}
function ModelsGetSubscriptionsSubscriptionFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "displayId": !exists(json, "display_id") ? void 0 : json["display_id"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "nextPaymentDate": !exists(json, "next_payment_date") ? void 0 : json["next_payment_date"],
    "paymentProvider": !exists(json, "payment_provider") ? void 0 : json["payment_provider"],
    "perks": !exists(json, "perks") ? void 0 : json["perks"].map(ModelsUsedPerksFromJSON),
    "renewalDate": !exists(json, "renewal_date") ? void 0 : json["renewal_date"],
    "status": !exists(json, "status") ? void 0 : json["status"],
    "treatments": !exists(json, "treatments") ? void 0 : json["treatments"].map(ModelsUsedTreatmentsFromJSON)
  };
}
function ModelsGetSubscriptionsSubscriptionToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "display_id": value.displayId,
    "id": value.id,
    "next_payment_date": value.nextPaymentDate,
    "payment_provider": value.paymentProvider,
    "perks": value.perks === void 0 ? void 0 : value.perks.map(ModelsUsedPerksToJSON),
    "renewal_date": value.renewalDate,
    "status": value.status,
    "treatments": value.treatments === void 0 ? void 0 : value.treatments.map(ModelsUsedTreatmentsToJSON)
  };
}

// src/generated/orchestrators/plans/models/ModelsGetSubscriptionAggregate.ts
function instanceOfModelsGetSubscriptionAggregate(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetSubscriptionAggregateFromJSON(json) {
  return ModelsGetSubscriptionAggregateFromJSONTyped(json);
}
function ModelsGetSubscriptionAggregateFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "customer": !exists(json, "customer") ? void 0 : ModelsGetSubscriptionsCustomerFromJSON(json["customer"]),
    "documents": !exists(json, "documents") ? void 0 : json["documents"],
    "patient": !exists(json, "patient") ? void 0 : ModelsGetSubscriptionsPatientFromJSON(json["patient"]),
    "paymentMethod": !exists(json, "payment_method") ? void 0 : json["payment_method"],
    "plan": !exists(json, "plan") ? void 0 : ModelsGetSubscriptionsPlanFromJSON(json["plan"]),
    "subscription": !exists(json, "subscription") ? void 0 : ModelsGetSubscriptionsSubscriptionFromJSON(json["subscription"]),
    "vendorClinician": !exists(json, "vendor_clinician") ? void 0 : json["vendor_clinician"]
  };
}
function ModelsGetSubscriptionAggregateToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "customer": ModelsGetSubscriptionsCustomerToJSON(value.customer),
    "documents": value.documents,
    "patient": ModelsGetSubscriptionsPatientToJSON(value.patient),
    "payment_method": value.paymentMethod,
    "plan": ModelsGetSubscriptionsPlanToJSON(value.plan),
    "subscription": ModelsGetSubscriptionsSubscriptionToJSON(value.subscription),
    "vendor_clinician": value.vendorClinician
  };
}

// src/generated/orchestrators/plans/models/ModelsGetSubscriptionResponse.ts
function instanceOfModelsGetSubscriptionResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetSubscriptionResponseFromJSON(json) {
  return ModelsGetSubscriptionResponseFromJSONTyped(json);
}
function ModelsGetSubscriptionResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "subscription": !exists(json, "subscription") ? void 0 : ModelsGetSubscriptionAggregateFromJSON(json["subscription"])
  };
}
function ModelsGetSubscriptionResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "subscription": ModelsGetSubscriptionAggregateToJSON(value.subscription)
  };
}

// src/generated/orchestrators/plans/models/ModelsGetSummaryCustomer.ts
function instanceOfModelsGetSummaryCustomer(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetSummaryCustomerFromJSON(json) {
  return ModelsGetSummaryCustomerFromJSONTyped(json);
}
function ModelsGetSummaryCustomerFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "birthDate": !exists(json, "birth_date") ? void 0 : json["birth_date"],
    "email": !exists(json, "email") ? void 0 : json["email"],
    "firstName": !exists(json, "first_name") ? void 0 : json["first_name"],
    "homeAddress": !exists(json, "home_address") ? void 0 : json["home_address"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "lastName": !exists(json, "last_name") ? void 0 : json["last_name"],
    "mobilePhone": !exists(json, "mobile_phone") ? void 0 : json["mobile_phone"]
  };
}
function ModelsGetSummaryCustomerToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "birth_date": value.birthDate,
    "email": value.email,
    "first_name": value.firstName,
    "home_address": value.homeAddress,
    "id": value.id,
    "last_name": value.lastName,
    "mobile_phone": value.mobilePhone
  };
}

// src/generated/orchestrators/plans/models/ModelsGetSummaryPatient.ts
function instanceOfModelsGetSummaryPatient(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetSummaryPatientFromJSON(json) {
  return ModelsGetSummaryPatientFromJSONTyped(json);
}
function ModelsGetSummaryPatientFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "age": !exists(json, "age") ? void 0 : json["age"],
    "birthdate": !exists(json, "birthdate") ? void 0 : json["birthdate"],
    "firstName": !exists(json, "first_name") ? void 0 : json["first_name"],
    "gender": !exists(json, "gender") ? void 0 : json["gender"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "lastName": !exists(json, "last_name") ? void 0 : json["last_name"],
    "relationship": !exists(json, "relationship") ? void 0 : json["relationship"]
  };
}
function ModelsGetSummaryPatientToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "age": value.age,
    "birthdate": value.birthdate,
    "first_name": value.firstName,
    "gender": value.gender,
    "id": value.id,
    "last_name": value.lastName,
    "relationship": value.relationship
  };
}

// src/generated/orchestrators/plans/models/ModelsGetSummaryPlan.ts
function instanceOfModelsGetSummaryPlan(value) {
  let isInstance = true;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "_private" in value;
  return isInstance;
}
function ModelsGetSummaryPlanFromJSON(json) {
  return ModelsGetSummaryPlanFromJSONTyped(json);
}
function ModelsGetSummaryPlanFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "currency": !exists(json, "currency") ? void 0 : json["currency"],
    "description": json["description"],
    "displayId": !exists(json, "display_id") ? void 0 : json["display_id"],
    "excludedGoodsServices": !exists(json, "excluded_goods_services") ? void 0 : json["excluded_goods_services"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "imageUrl": !exists(json, "image_url") ? void 0 : json["image_url"],
    "internalDescription": !exists(json, "internal_description") ? void 0 : json["internal_description"],
    "internalReference": !exists(json, "internal_reference") ? void 0 : json["internal_reference"],
    "name": !exists(json, "name") ? void 0 : json["name"],
    "price": !exists(json, "price") ? void 0 : json["price"],
    "_private": json["private"],
    "status": !exists(json, "status") ? void 0 : json["status"]
  };
}
function ModelsGetSummaryPlanToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "currency": value.currency,
    "description": value.description,
    "display_id": value.displayId,
    "excluded_goods_services": value.excludedGoodsServices,
    "id": value.id,
    "image_url": value.imageUrl,
    "internal_description": value.internalDescription,
    "internal_reference": value.internalReference,
    "name": value.name,
    "price": value.price,
    "private": value._private,
    "status": value.status
  };
}

// src/generated/orchestrators/plans/models/ModelsSubscriptionStatus.ts
function instanceOfModelsSubscriptionStatus(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsSubscriptionStatusFromJSON(json) {
  return ModelsSubscriptionStatusFromJSONTyped(json);
}
function ModelsSubscriptionStatusFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "appliedAt": !exists(json, "applied_at") ? void 0 : json["applied_at"],
    "code": !exists(json, "code") ? void 0 : json["code"]
  };
}
function ModelsSubscriptionStatusToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "applied_at": value.appliedAt,
    "code": value.code
  };
}

// src/generated/orchestrators/plans/models/ModelsGetSummarySubscription.ts
function instanceOfModelsGetSummarySubscription(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetSummarySubscriptionFromJSON(json) {
  return ModelsGetSummarySubscriptionFromJSONTyped(json);
}
function ModelsGetSummarySubscriptionFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "displayId": !exists(json, "display_id") ? void 0 : json["display_id"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "perks": !exists(json, "perks") ? void 0 : json["perks"].map(ModelsUsedPerksFromJSON),
    "renewalDate": !exists(json, "renewal_date") ? void 0 : json["renewal_date"],
    "status": !exists(json, "status") ? void 0 : ModelsSubscriptionStatusFromJSON(json["status"]),
    "treatments": !exists(json, "treatments") ? void 0 : json["treatments"].map(ModelsUsedTreatmentsFromJSON)
  };
}
function ModelsGetSummarySubscriptionToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "display_id": value.displayId,
    "id": value.id,
    "perks": value.perks === void 0 ? void 0 : value.perks.map(ModelsUsedPerksToJSON),
    "renewal_date": value.renewalDate,
    "status": ModelsSubscriptionStatusToJSON(value.status),
    "treatments": value.treatments === void 0 ? void 0 : value.treatments.map(ModelsUsedTreatmentsToJSON)
  };
}

// src/generated/orchestrators/plans/models/ModelsGetSummaryVendor.ts
function instanceOfModelsGetSummaryVendor(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetSummaryVendorFromJSON(json) {
  return ModelsGetSummaryVendorFromJSONTyped(json);
}
function ModelsGetSummaryVendorFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "code": !exists(json, "code") ? void 0 : json["code"],
    "email": !exists(json, "email") ? void 0 : json["email"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "legacyId": !exists(json, "legacy_id") ? void 0 : json["legacy_id"],
    "logoUrl": !exists(json, "logo_url") ? void 0 : json["logo_url"],
    "phoneNumber": !exists(json, "phone_number") ? void 0 : json["phone_number"],
    "primaryColor": !exists(json, "primary_color") ? void 0 : json["primary_color"],
    "tradingName": !exists(json, "trading_name") ? void 0 : json["trading_name"]
  };
}
function ModelsGetSummaryVendorToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "code": value.code,
    "email": value.email,
    "id": value.id,
    "legacy_id": value.legacyId,
    "logo_url": value.logoUrl,
    "phone_number": value.phoneNumber,
    "primary_color": value.primaryColor,
    "trading_name": value.tradingName
  };
}

// src/generated/orchestrators/plans/models/ModelsGetSubscriptionSummaryResponse.ts
function instanceOfModelsGetSubscriptionSummaryResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetSubscriptionSummaryResponseFromJSON(json) {
  return ModelsGetSubscriptionSummaryResponseFromJSONTyped(json);
}
function ModelsGetSubscriptionSummaryResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "customer": !exists(json, "customer") ? void 0 : ModelsGetSummaryCustomerFromJSON(json["customer"]),
    "documentDetails": !exists(json, "document_details") ? void 0 : json["document_details"],
    "patient": !exists(json, "patient") ? void 0 : ModelsGetSummaryPatientFromJSON(json["patient"]),
    "paymentDetails": !exists(json, "payment_details") ? void 0 : json["payment_details"],
    "plan": !exists(json, "plan") ? void 0 : ModelsGetSummaryPlanFromJSON(json["plan"]),
    "subscription": !exists(json, "subscription") ? void 0 : ModelsGetSummarySubscriptionFromJSON(json["subscription"]),
    "vendor": !exists(json, "vendor") ? void 0 : ModelsGetSummaryVendorFromJSON(json["vendor"]),
    "vendorClinician": !exists(json, "vendor_clinician") ? void 0 : json["vendor_clinician"]
  };
}
function ModelsGetSubscriptionSummaryResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "customer": ModelsGetSummaryCustomerToJSON(value.customer),
    "document_details": value.documentDetails,
    "patient": ModelsGetSummaryPatientToJSON(value.patient),
    "payment_details": value.paymentDetails,
    "plan": ModelsGetSummaryPlanToJSON(value.plan),
    "subscription": ModelsGetSummarySubscriptionToJSON(value.subscription),
    "vendor": ModelsGetSummaryVendorToJSON(value.vendor),
    "vendor_clinician": value.vendorClinician
  };
}

// src/generated/orchestrators/plans/models/ModelsGetVendorPublicResponse.ts
function instanceOfModelsGetVendorPublicResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetVendorPublicResponseFromJSON(json) {
  return ModelsGetVendorPublicResponseFromJSONTyped(json);
}
function ModelsGetVendorPublicResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "plans": !exists(json, "plans") ? void 0 : json["plans"].map(ModelsGetInvitePlanFromJSON),
    "vendor": !exists(json, "vendor") ? void 0 : ModelsGetInviteVendorFromJSON(json["vendor"])
  };
}
function ModelsGetVendorPublicResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "plans": value.plans === void 0 ? void 0 : value.plans.map(ModelsGetInvitePlanToJSON),
    "vendor": ModelsGetInviteVendorToJSON(value.vendor)
  };
}

// src/generated/orchestrators/plans/models/ModelsVendorClinician.ts
function instanceOfModelsVendorClinician(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsVendorClinicianFromJSON(json) {
  return ModelsVendorClinicianFromJSONTyped(json);
}
function ModelsVendorClinicianFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "clinician": !exists(json, "clinician") ? void 0 : ModelsClinicianFromJSON(json["clinician"]),
    "displayName": !exists(json, "display_name") ? void 0 : json["display_name"],
    "email": !exists(json, "email") ? void 0 : json["email"],
    "phone": !exists(json, "phone") ? void 0 : json["phone"],
    "status": !exists(json, "status") ? void 0 : json["status"]
  };
}
function ModelsVendorClinicianToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "clinician": ModelsClinicianToJSON(value.clinician),
    "display_name": value.displayName,
    "email": value.email,
    "phone": value.phone,
    "status": value.status
  };
}

// src/generated/orchestrators/plans/models/ModelsVendorFeatureFlag.ts
function instanceOfModelsVendorFeatureFlag(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsVendorFeatureFlagFromJSON(json) {
  return ModelsVendorFeatureFlagFromJSONTyped(json);
}
function ModelsVendorFeatureFlagFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "featureFlag": !exists(json, "feature_flag") ? void 0 : ModelsFeatureFlagFromJSON(json["feature_flag"]),
    "value": !exists(json, "value") ? void 0 : json["value"],
    "vendorId": !exists(json, "vendor_id") ? void 0 : json["vendor_id"]
  };
}
function ModelsVendorFeatureFlagToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "feature_flag": ModelsFeatureFlagToJSON(value.featureFlag),
    "value": value.value,
    "vendor_id": value.vendorId
  };
}

// src/generated/orchestrators/plans/models/ModelsVendor.ts
function instanceOfModelsVendor(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsVendorFromJSON(json) {
  return ModelsVendorFromJSONTyped(json);
}
function ModelsVendorFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "code": !exists(json, "code") ? void 0 : json["code"],
    "connectedAccount": !exists(json, "connected_account") ? void 0 : ModelsConnectedAccountFromJSON(json["connected_account"]),
    "directDebitProvider": !exists(json, "direct_debit_provider") ? void 0 : json["direct_debit_provider"],
    "email": !exists(json, "email") ? void 0 : json["email"],
    "featureFlags": !exists(json, "feature_flags") ? void 0 : json["feature_flags"].map(ModelsVendorFeatureFlagFromJSON),
    "id": !exists(json, "id") ? void 0 : json["id"],
    "legacyId": !exists(json, "legacy_id") ? void 0 : json["legacy_id"],
    "legalForm": !exists(json, "legal_form") ? void 0 : json["legal_form"],
    "legalName": !exists(json, "legal_name") ? void 0 : json["legal_name"],
    "logoUrl": !exists(json, "logo_url") ? void 0 : json["logo_url"],
    "phoneNumber": !exists(json, "phone_number") ? void 0 : json["phone_number"],
    "primaryColor": !exists(json, "primary_color") ? void 0 : json["primary_color"],
    "slug": !exists(json, "slug") ? void 0 : json["slug"],
    "tradingName": !exists(json, "trading_name") ? void 0 : json["trading_name"],
    "vendorClinicians": !exists(json, "vendor_clinicians") ? void 0 : json["vendor_clinicians"].map(ModelsVendorClinicianFromJSON)
  };
}
function ModelsVendorToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "code": value.code,
    "connected_account": ModelsConnectedAccountToJSON(value.connectedAccount),
    "direct_debit_provider": value.directDebitProvider,
    "email": value.email,
    "feature_flags": value.featureFlags === void 0 ? void 0 : value.featureFlags.map(ModelsVendorFeatureFlagToJSON),
    "id": value.id,
    "legacy_id": value.legacyId,
    "legal_form": value.legalForm,
    "legal_name": value.legalName,
    "logo_url": value.logoUrl,
    "phone_number": value.phoneNumber,
    "primary_color": value.primaryColor,
    "slug": value.slug,
    "trading_name": value.tradingName,
    "vendor_clinicians": value.vendorClinicians === void 0 ? void 0 : value.vendorClinicians.map(ModelsVendorClinicianToJSON)
  };
}

// src/generated/orchestrators/plans/models/ModelsGetVendorResponse.ts
function instanceOfModelsGetVendorResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsGetVendorResponseFromJSON(json) {
  return ModelsGetVendorResponseFromJSONTyped(json);
}
function ModelsGetVendorResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "vendor": !exists(json, "vendor") ? void 0 : ModelsVendorFromJSON(json["vendor"])
  };
}
function ModelsGetVendorResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "vendor": ModelsVendorToJSON(value.vendor)
  };
}

// src/generated/orchestrators/plans/models/ModelsOnboardDirectDebitCustomerRequest.ts
function instanceOfModelsOnboardDirectDebitCustomerRequest(value) {
  let isInstance = true;
  isInstance = isInstance && "bankAccountToken" in value;
  isInstance = isInstance && "bankSortCode" in value;
  return isInstance;
}
function ModelsOnboardDirectDebitCustomerRequestFromJSON(json) {
  return ModelsOnboardDirectDebitCustomerRequestFromJSONTyped(json);
}
function ModelsOnboardDirectDebitCustomerRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "bankAccountToken": json["bank_account_token"],
    "bankSortCode": json["bank_sort_code"]
  };
}
function ModelsOnboardDirectDebitCustomerRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "bank_account_token": value.bankAccountToken,
    "bank_sort_code": value.bankSortCode
  };
}

// src/generated/orchestrators/plans/models/ModelsOnboardDirectDebitCustomerResponse.ts
function instanceOfModelsOnboardDirectDebitCustomerResponse(value) {
  let isInstance = true;
  isInstance = isInstance && "bankAccount" in value;
  isInstance = isInstance && "directDebitCustomer" in value;
  return isInstance;
}
function ModelsOnboardDirectDebitCustomerResponseFromJSON(json) {
  return ModelsOnboardDirectDebitCustomerResponseFromJSONTyped(json);
}
function ModelsOnboardDirectDebitCustomerResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "bankAccount": ModelsBankAccountFromJSON(json["bank_account"]),
    "directDebitCustomer": ModelsDirectDebitCustomerFromJSON(json["direct_debit_customer"])
  };
}
function ModelsOnboardDirectDebitCustomerResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "bank_account": ModelsBankAccountToJSON(value.bankAccount),
    "direct_debit_customer": ModelsDirectDebitCustomerToJSON(value.directDebitCustomer)
  };
}

// src/generated/orchestrators/plans/models/ModelsOnboardStripeCustomerRequest.ts
function instanceOfModelsOnboardStripeCustomerRequest(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsOnboardStripeCustomerRequestFromJSON(json) {
  return ModelsOnboardStripeCustomerRequestFromJSONTyped(json);
}
function ModelsOnboardStripeCustomerRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "membershipId": !exists(json, "membership_id") ? void 0 : json["membership_id"]
  };
}
function ModelsOnboardStripeCustomerRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "membership_id": value.membershipId
  };
}

// src/generated/orchestrators/plans/models/ModelsSetupIntent.ts
function instanceOfModelsSetupIntent(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsSetupIntentFromJSON(json) {
  return ModelsSetupIntentFromJSONTyped(json);
}
function ModelsSetupIntentFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "clientSecret": !exists(json, "client_secret") ? void 0 : json["client_secret"],
    "connectedAccountId": !exists(json, "connected_account_id") ? void 0 : json["connected_account_id"],
    "status": !exists(json, "status") ? void 0 : json["status"]
  };
}
function ModelsSetupIntentToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "client_secret": value.clientSecret,
    "connected_account_id": value.connectedAccountId,
    "status": value.status
  };
}

// src/generated/orchestrators/plans/models/ModelsOnboardStripeCustomerResponse.ts
function instanceOfModelsOnboardStripeCustomerResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsOnboardStripeCustomerResponseFromJSON(json) {
  return ModelsOnboardStripeCustomerResponseFromJSONTyped(json);
}
function ModelsOnboardStripeCustomerResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "setupIntent": !exists(json, "setup_intent") ? void 0 : ModelsSetupIntentFromJSON(json["setup_intent"])
  };
}
function ModelsOnboardStripeCustomerResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "setup_intent": ModelsSetupIntentToJSON(value.setupIntent)
  };
}

// src/generated/orchestrators/plans/models/ModelsUpdatePatientOnInvite.ts
function instanceOfModelsUpdatePatientOnInvite(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsUpdatePatientOnInviteFromJSON(json) {
  return ModelsUpdatePatientOnInviteFromJSONTyped(json);
}
function ModelsUpdatePatientOnInviteFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "birthDate": !exists(json, "birth_date") ? void 0 : json["birth_date"],
    "customerId": !exists(json, "customer_id") ? void 0 : json["customer_id"],
    "firstName": !exists(json, "first_name") ? void 0 : json["first_name"],
    "gender": !exists(json, "gender") ? void 0 : json["gender"],
    "lastName": !exists(json, "last_name") ? void 0 : json["last_name"]
  };
}
function ModelsUpdatePatientOnInviteToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "birth_date": value.birthDate,
    "customer_id": value.customerId,
    "first_name": value.firstName,
    "gender": value.gender,
    "last_name": value.lastName
  };
}

// src/generated/orchestrators/plans/models/ModelsPlanSubscriptionInviteUpdatePatientRequest.ts
function instanceOfModelsPlanSubscriptionInviteUpdatePatientRequest(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsPlanSubscriptionInviteUpdatePatientRequestFromJSON(json) {
  return ModelsPlanSubscriptionInviteUpdatePatientRequestFromJSONTyped(json);
}
function ModelsPlanSubscriptionInviteUpdatePatientRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "patient": !exists(json, "patient") ? void 0 : ModelsUpdatePatientOnInviteFromJSON(json["patient"])
  };
}
function ModelsPlanSubscriptionInviteUpdatePatientRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "patient": ModelsUpdatePatientOnInviteToJSON(value.patient)
  };
}

// src/generated/orchestrators/plans/models/ModelsRequestedPerk.ts
function instanceOfModelsRequestedPerk(value) {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  return isInstance;
}
function ModelsRequestedPerkFromJSON(json) {
  return ModelsRequestedPerkFromJSONTyped(json);
}
function ModelsRequestedPerkFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "id": json["id"],
    "quantity": !exists(json, "quantity") ? void 0 : json["quantity"]
  };
}
function ModelsRequestedPerkToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "id": value.id,
    "quantity": value.quantity
  };
}

// src/generated/orchestrators/plans/models/ModelsRequestedTreatment.ts
function instanceOfModelsRequestedTreatment(value) {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "quantity" in value;
  return isInstance;
}
function ModelsRequestedTreatmentFromJSON(json) {
  return ModelsRequestedTreatmentFromJSONTyped(json);
}
function ModelsRequestedTreatmentFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "id": json["id"],
    "quantity": json["quantity"]
  };
}
function ModelsRequestedTreatmentToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "id": value.id,
    "quantity": value.quantity
  };
}

// src/generated/orchestrators/plans/models/ModelsRegisterClaimRequest.ts
function instanceOfModelsRegisterClaimRequest(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsRegisterClaimRequestFromJSON(json) {
  return ModelsRegisterClaimRequestFromJSONTyped(json);
}
function ModelsRegisterClaimRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "claimDate": !exists(json, "claim_date") ? void 0 : json["claim_date"],
    "clinicianId": !exists(json, "clinician_id") ? void 0 : json["clinician_id"],
    "perks": !exists(json, "perks") ? void 0 : json["perks"].map(ModelsRequestedPerkFromJSON),
    "treatments": !exists(json, "treatments") ? void 0 : json["treatments"].map(ModelsRequestedTreatmentFromJSON)
  };
}
function ModelsRegisterClaimRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "claim_date": value.claimDate,
    "clinician_id": value.clinicianId,
    "perks": value.perks === void 0 ? void 0 : value.perks.map(ModelsRequestedPerkToJSON),
    "treatments": value.treatments === void 0 ? void 0 : value.treatments.map(ModelsRequestedTreatmentToJSON)
  };
}

// src/generated/orchestrators/plans/models/ModelsRegisterCustomer.ts
function instanceOfModelsRegisterCustomer(value) {
  let isInstance = true;
  isInstance = isInstance && "countryCode" in value;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "firstName" in value;
  isInstance = isInstance && "gender" in value;
  isInstance = isInstance && "lastName" in value;
  return isInstance;
}
function ModelsRegisterCustomerFromJSON(json) {
  return ModelsRegisterCustomerFromJSONTyped(json);
}
function ModelsRegisterCustomerFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "countryCode": json["country_code"],
    "email": json["email"],
    "firstName": json["first_name"],
    "gender": json["gender"],
    "lastName": json["last_name"]
  };
}
function ModelsRegisterCustomerToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "country_code": value.countryCode,
    "email": value.email,
    "first_name": value.firstName,
    "gender": value.gender,
    "last_name": value.lastName
  };
}

// src/generated/orchestrators/plans/models/ModelsRegisterCustomerResponse.ts
function instanceOfModelsRegisterCustomerResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsRegisterCustomerResponseFromJSON(json) {
  return ModelsRegisterCustomerResponseFromJSONTyped(json);
}
function ModelsRegisterCustomerResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "token": !exists(json, "token") ? void 0 : json["token"]
  };
}
function ModelsRegisterCustomerResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "token": value.token
  };
}

// src/generated/orchestrators/plans/models/ModelsSelectFeatureFlagsRequest.ts
function instanceOfModelsSelectFeatureFlagsRequest(value) {
  let isInstance = true;
  isInstance = isInstance && "vendorId" in value;
  return isInstance;
}
function ModelsSelectFeatureFlagsRequestFromJSON(json) {
  return ModelsSelectFeatureFlagsRequestFromJSONTyped(json);
}
function ModelsSelectFeatureFlagsRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "vendorId": json["vendor_id"]
  };
}
function ModelsSelectFeatureFlagsRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "vendor_id": value.vendorId
  };
}

// src/generated/orchestrators/plans/models/ModelsSelectPlansAggregate.ts
function instanceOfModelsSelectPlansAggregate(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsSelectPlansAggregateFromJSON(json) {
  return ModelsSelectPlansAggregateFromJSONTyped(json);
}
function ModelsSelectPlansAggregateFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "currency": !exists(json, "currency") ? void 0 : json["currency"],
    "displayId": !exists(json, "display_id") ? void 0 : json["display_id"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "internalReference": !exists(json, "internal_reference") ? void 0 : json["internal_reference"],
    "membershipCount": !exists(json, "membership_count") ? void 0 : json["membership_count"],
    "name": !exists(json, "name") ? void 0 : json["name"],
    "price": !exists(json, "price") ? void 0 : json["price"],
    "_private": !exists(json, "private") ? void 0 : json["private"],
    "slug": !exists(json, "slug") ? void 0 : json["slug"],
    "status": !exists(json, "status") ? void 0 : json["status"]
  };
}
function ModelsSelectPlansAggregateToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "currency": value.currency,
    "display_id": value.displayId,
    "id": value.id,
    "internal_reference": value.internalReference,
    "membership_count": value.membershipCount,
    "name": value.name,
    "price": value.price,
    "private": value._private,
    "slug": value.slug,
    "status": value.status
  };
}

// src/generated/orchestrators/plans/models/ThttpPaginationSummary.ts
function instanceOfThttpPaginationSummary(value) {
  let isInstance = true;
  return isInstance;
}
function ThttpPaginationSummaryFromJSON(json) {
  return ThttpPaginationSummaryFromJSONTyped(json);
}
function ThttpPaginationSummaryFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "total": !exists(json, "total") ? void 0 : json["total"]
  };
}
function ThttpPaginationSummaryToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "total": value.total
  };
}

// src/generated/orchestrators/plans/models/ModelsSelectPlansResponse.ts
function instanceOfModelsSelectPlansResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsSelectPlansResponseFromJSON(json) {
  return ModelsSelectPlansResponseFromJSONTyped(json);
}
function ModelsSelectPlansResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "paginationSummary": !exists(json, "pagination_summary") ? void 0 : ThttpPaginationSummaryFromJSON(json["pagination_summary"]),
    "plans": !exists(json, "plans") ? void 0 : json["plans"].map(ModelsSelectPlansAggregateFromJSON)
  };
}
function ModelsSelectPlansResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "pagination_summary": ThttpPaginationSummaryToJSON(value.paginationSummary),
    "plans": value.plans === void 0 ? void 0 : value.plans.map(ModelsSelectPlansAggregateToJSON)
  };
}

// src/generated/orchestrators/plans/models/ModelsSelectSubscriptionsCustomer.ts
function instanceOfModelsSelectSubscriptionsCustomer(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsSelectSubscriptionsCustomerFromJSON(json) {
  return ModelsSelectSubscriptionsCustomerFromJSONTyped(json);
}
function ModelsSelectSubscriptionsCustomerFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "displayId": !exists(json, "display_id") ? void 0 : json["display_id"],
    "email": !exists(json, "email") ? void 0 : json["email"],
    "firstName": !exists(json, "first_name") ? void 0 : json["first_name"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "lastName": !exists(json, "last_name") ? void 0 : json["last_name"],
    "legacyId": !exists(json, "legacy_id") ? void 0 : json["legacy_id"]
  };
}
function ModelsSelectSubscriptionsCustomerToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "display_id": value.displayId,
    "email": value.email,
    "first_name": value.firstName,
    "id": value.id,
    "last_name": value.lastName,
    "legacy_id": value.legacyId
  };
}

// src/generated/orchestrators/plans/models/ModelsSelectSubscriptionsInvite.ts
function instanceOfModelsSelectSubscriptionsInvite(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsSelectSubscriptionsInviteFromJSON(json) {
  return ModelsSelectSubscriptionsInviteFromJSONTyped(json);
}
function ModelsSelectSubscriptionsInviteFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "id": !exists(json, "id") ? void 0 : json["id"],
    "isUsed": !exists(json, "is_used") ? void 0 : json["is_used"],
    "token": !exists(json, "token") ? void 0 : json["token"]
  };
}
function ModelsSelectSubscriptionsInviteToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "id": value.id,
    "is_used": value.isUsed,
    "token": value.token
  };
}

// src/generated/orchestrators/plans/models/ModelsSelectSubscriptionsPatient.ts
function instanceOfModelsSelectSubscriptionsPatient(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsSelectSubscriptionsPatientFromJSON(json) {
  return ModelsSelectSubscriptionsPatientFromJSONTyped(json);
}
function ModelsSelectSubscriptionsPatientFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "displayId": !exists(json, "display_id") ? void 0 : json["display_id"],
    "firstName": !exists(json, "first_name") ? void 0 : json["first_name"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "lastName": !exists(json, "last_name") ? void 0 : json["last_name"]
  };
}
function ModelsSelectSubscriptionsPatientToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "display_id": value.displayId,
    "first_name": value.firstName,
    "id": value.id,
    "last_name": value.lastName
  };
}

// src/generated/orchestrators/plans/models/ModelsSelectSubscriptionsPlan.ts
function instanceOfModelsSelectSubscriptionsPlan(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsSelectSubscriptionsPlanFromJSON(json) {
  return ModelsSelectSubscriptionsPlanFromJSONTyped(json);
}
function ModelsSelectSubscriptionsPlanFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "currency": !exists(json, "currency") ? void 0 : json["currency"],
    "displayId": !exists(json, "display_id") ? void 0 : json["display_id"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "name": !exists(json, "name") ? void 0 : json["name"],
    "price": !exists(json, "price") ? void 0 : json["price"]
  };
}
function ModelsSelectSubscriptionsPlanToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "currency": value.currency,
    "display_id": value.displayId,
    "id": value.id,
    "name": value.name,
    "price": value.price
  };
}

// src/generated/orchestrators/plans/models/ModelsSelectSubscriptionsSubscription.ts
function instanceOfModelsSelectSubscriptionsSubscription(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsSelectSubscriptionsSubscriptionFromJSON(json) {
  return ModelsSelectSubscriptionsSubscriptionFromJSONTyped(json);
}
function ModelsSelectSubscriptionsSubscriptionFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "createdAt": !exists(json, "created_at") ? void 0 : json["created_at"],
    "displayId": !exists(json, "display_id") ? void 0 : json["display_id"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "paymentProvider": !exists(json, "payment_provider") ? void 0 : json["payment_provider"],
    "status": !exists(json, "status") ? void 0 : json["status"]
  };
}
function ModelsSelectSubscriptionsSubscriptionToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "created_at": value.createdAt,
    "display_id": value.displayId,
    "id": value.id,
    "payment_provider": value.paymentProvider,
    "status": value.status
  };
}

// src/generated/orchestrators/plans/models/ModelsSelectSubscriptionsVendor.ts
function instanceOfModelsSelectSubscriptionsVendor(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsSelectSubscriptionsVendorFromJSON(json) {
  return ModelsSelectSubscriptionsVendorFromJSONTyped(json);
}
function ModelsSelectSubscriptionsVendorFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "id": !exists(json, "id") ? void 0 : json["id"],
    "logoUrl": !exists(json, "logo_url") ? void 0 : json["logo_url"],
    "name": !exists(json, "name") ? void 0 : json["name"]
  };
}
function ModelsSelectSubscriptionsVendorToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "id": value.id,
    "logo_url": value.logoUrl,
    "name": value.name
  };
}

// src/generated/orchestrators/plans/models/ModelsSelectSubscriptionAggregate.ts
function instanceOfModelsSelectSubscriptionAggregate(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsSelectSubscriptionAggregateFromJSON(json) {
  return ModelsSelectSubscriptionAggregateFromJSONTyped(json);
}
function ModelsSelectSubscriptionAggregateFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "customer": !exists(json, "customer") ? void 0 : ModelsSelectSubscriptionsCustomerFromJSON(json["customer"]),
    "invites": !exists(json, "invites") ? void 0 : json["invites"].map(ModelsSelectSubscriptionsInviteFromJSON),
    "patient": !exists(json, "patient") ? void 0 : ModelsSelectSubscriptionsPatientFromJSON(json["patient"]),
    "plan": !exists(json, "plan") ? void 0 : ModelsSelectSubscriptionsPlanFromJSON(json["plan"]),
    "subscription": !exists(json, "subscription") ? void 0 : ModelsSelectSubscriptionsSubscriptionFromJSON(json["subscription"]),
    "vendor": !exists(json, "vendor") ? void 0 : ModelsSelectSubscriptionsVendorFromJSON(json["vendor"])
  };
}
function ModelsSelectSubscriptionAggregateToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "customer": ModelsSelectSubscriptionsCustomerToJSON(value.customer),
    "invites": value.invites === void 0 ? void 0 : value.invites.map(ModelsSelectSubscriptionsInviteToJSON),
    "patient": ModelsSelectSubscriptionsPatientToJSON(value.patient),
    "plan": ModelsSelectSubscriptionsPlanToJSON(value.plan),
    "subscription": ModelsSelectSubscriptionsSubscriptionToJSON(value.subscription),
    "vendor": ModelsSelectSubscriptionsVendorToJSON(value.vendor)
  };
}

// src/generated/orchestrators/plans/models/ModelsSelectSubscriptionsRequest.ts
function instanceOfModelsSelectSubscriptionsRequest(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsSelectSubscriptionsRequestFromJSON(json) {
  return ModelsSelectSubscriptionsRequestFromJSONTyped(json);
}
function ModelsSelectSubscriptionsRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "planIds": !exists(json, "plan_ids") ? void 0 : json["plan_ids"],
    "searchField": !exists(json, "search_field") ? void 0 : json["search_field"],
    "statuses": !exists(json, "statuses") ? void 0 : json["statuses"]
  };
}
function ModelsSelectSubscriptionsRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "plan_ids": value.planIds,
    "search_field": value.searchField,
    "statuses": value.statuses
  };
}

// src/generated/orchestrators/plans/models/ModelsSelectSubscriptionsResponse.ts
function instanceOfModelsSelectSubscriptionsResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsSelectSubscriptionsResponseFromJSON(json) {
  return ModelsSelectSubscriptionsResponseFromJSONTyped(json);
}
function ModelsSelectSubscriptionsResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "paginationSummary": !exists(json, "pagination_summary") ? void 0 : ThttpPaginationSummaryFromJSON(json["pagination_summary"]),
    "subscriptions": !exists(json, "subscriptions") ? void 0 : json["subscriptions"].map(ModelsSelectSubscriptionAggregateFromJSON)
  };
}
function ModelsSelectSubscriptionsResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "pagination_summary": ThttpPaginationSummaryToJSON(value.paginationSummary),
    "subscriptions": value.subscriptions === void 0 ? void 0 : value.subscriptions.map(ModelsSelectSubscriptionAggregateToJSON)
  };
}

// src/generated/orchestrators/plans/models/ModelsSwapSubscriptionPlanRequest.ts
function instanceOfModelsSwapSubscriptionPlanRequest(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsSwapSubscriptionPlanRequestFromJSON(json) {
  return ModelsSwapSubscriptionPlanRequestFromJSONTyped(json);
}
function ModelsSwapSubscriptionPlanRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "planId": !exists(json, "plan_id") ? void 0 : json["plan_id"]
  };
}
function ModelsSwapSubscriptionPlanRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "plan_id": value.planId
  };
}

// src/generated/orchestrators/plans/models/ModelsUpdatePerkParams.ts
function instanceOfModelsUpdatePerkParams(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsUpdatePerkParamsFromJSON(json) {
  return ModelsUpdatePerkParamsFromJSONTyped(json);
}
function ModelsUpdatePerkParamsFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "id": !exists(json, "id") ? void 0 : json["id"],
    "name": !exists(json, "name") ? void 0 : json["name"],
    "quantity": !exists(json, "quantity") ? void 0 : json["quantity"]
  };
}
function ModelsUpdatePerkParamsToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "id": value.id,
    "name": value.name,
    "quantity": value.quantity
  };
}

// src/generated/orchestrators/plans/models/ModelsUpdateTreatmentParams.ts
function instanceOfModelsUpdateTreatmentParams(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsUpdateTreatmentParamsFromJSON(json) {
  return ModelsUpdateTreatmentParamsFromJSONTyped(json);
}
function ModelsUpdateTreatmentParamsFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "category": !exists(json, "category") ? void 0 : json["category"],
    "id": !exists(json, "id") ? void 0 : json["id"],
    "legacyId": !exists(json, "legacy_id") ? void 0 : json["legacy_id"],
    "name": !exists(json, "name") ? void 0 : json["name"],
    "quantity": !exists(json, "quantity") ? void 0 : json["quantity"]
  };
}
function ModelsUpdateTreatmentParamsToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "category": value.category,
    "id": value.id,
    "legacy_id": value.legacyId,
    "name": value.name,
    "quantity": value.quantity
  };
}

// src/generated/orchestrators/plans/models/ModelsUpdatePlanRequest.ts
function instanceOfModelsUpdatePlanRequest(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsUpdatePlanRequestFromJSON(json) {
  return ModelsUpdatePlanRequestFromJSONTyped(json);
}
function ModelsUpdatePlanRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "deletePerks": !exists(json, "delete_perks") ? void 0 : json["delete_perks"],
    "deleteTreatments": !exists(json, "delete_treatments") ? void 0 : json["delete_treatments"],
    "description": !exists(json, "description") ? void 0 : json["description"],
    "excludedGoodsServices": !exists(json, "excluded_goods_services") ? void 0 : json["excluded_goods_services"],
    "imageUrl": !exists(json, "image_url") ? void 0 : json["image_url"],
    "internalDescription": !exists(json, "internal_description") ? void 0 : json["internal_description"],
    "internalReference": !exists(json, "internal_reference") ? void 0 : json["internal_reference"],
    "name": !exists(json, "name") ? void 0 : json["name"],
    "price": !exists(json, "price") ? void 0 : json["price"],
    "_private": !exists(json, "private") ? void 0 : json["private"],
    "slug": !exists(json, "slug") ? void 0 : json["slug"],
    "upsertPerks": !exists(json, "upsert_perks") ? void 0 : json["upsert_perks"].map(ModelsUpdatePerkParamsFromJSON),
    "upsertTreatments": !exists(json, "upsert_treatments") ? void 0 : json["upsert_treatments"].map(ModelsUpdateTreatmentParamsFromJSON)
  };
}
function ModelsUpdatePlanRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "delete_perks": value.deletePerks,
    "delete_treatments": value.deleteTreatments,
    "description": value.description,
    "excluded_goods_services": value.excludedGoodsServices,
    "image_url": value.imageUrl,
    "internal_description": value.internalDescription,
    "internal_reference": value.internalReference,
    "name": value.name,
    "price": value.price,
    "private": value._private,
    "slug": value.slug,
    "upsert_perks": value.upsertPerks === void 0 ? void 0 : value.upsertPerks.map(ModelsUpdatePerkParamsToJSON),
    "upsert_treatments": value.upsertTreatments === void 0 ? void 0 : value.upsertTreatments.map(ModelsUpdateTreatmentParamsToJSON)
  };
}

// src/generated/orchestrators/plans/models/ModelsUpdatePlanResponse.ts
function instanceOfModelsUpdatePlanResponse(value) {
  let isInstance = true;
  isInstance = isInstance && "plan" in value;
  return isInstance;
}
function ModelsUpdatePlanResponseFromJSON(json) {
  return ModelsUpdatePlanResponseFromJSONTyped(json);
}
function ModelsUpdatePlanResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "plan": ModelsPlanFromJSON(json["plan"])
  };
}
function ModelsUpdatePlanResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "plan": ModelsPlanToJSON(value.plan)
  };
}

// src/generated/orchestrators/plans/models/ModelsUpdateSubscriptionPatientConsumerRequest.ts
function instanceOfModelsUpdateSubscriptionPatientConsumerRequest(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsUpdateSubscriptionPatientConsumerRequestFromJSON(json) {
  return ModelsUpdateSubscriptionPatientConsumerRequestFromJSONTyped(json);
}
function ModelsUpdateSubscriptionPatientConsumerRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "confirmationCode": !exists(json, "confirmation_code") ? void 0 : json["confirmation_code"]
  };
}
function ModelsUpdateSubscriptionPatientConsumerRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "confirmation_code": value.confirmationCode
  };
}

// src/generated/orchestrators/plans/models/ModelsUpdateSubscriptionPatientVendorRequest.ts
function instanceOfModelsUpdateSubscriptionPatientVendorRequest(value) {
  let isInstance = true;
  return isInstance;
}
function ModelsUpdateSubscriptionPatientVendorRequestFromJSON(json) {
  return ModelsUpdateSubscriptionPatientVendorRequestFromJSONTyped(json);
}
function ModelsUpdateSubscriptionPatientVendorRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "addressLine1": !exists(json, "address_line_1") ? void 0 : json["address_line_1"],
    "addressLine2": !exists(json, "address_line_2") ? void 0 : json["address_line_2"],
    "birthDate": !exists(json, "birth_date") ? void 0 : json["birth_date"],
    "buildingName": !exists(json, "building_name") ? void 0 : json["building_name"],
    "buildingNumber": !exists(json, "building_number") ? void 0 : json["building_number"],
    "city": !exists(json, "city") ? void 0 : json["city"],
    "country": !exists(json, "country") ? void 0 : json["country"],
    "countryIso2": !exists(json, "country_iso_2") ? void 0 : json["country_iso_2"],
    "email": !exists(json, "email") ? void 0 : json["email"],
    "externalId": !exists(json, "external_id") ? void 0 : json["external_id"],
    "firstName": !exists(json, "first_name") ? void 0 : json["first_name"],
    "gender": !exists(json, "gender") ? void 0 : json["gender"],
    "label": !exists(json, "label") ? void 0 : json["label"],
    "lastName": !exists(json, "last_name") ? void 0 : json["last_name"],
    "mobileNumber": !exists(json, "mobile_number") ? void 0 : json["mobile_number"],
    "postcode": !exists(json, "postcode") ? void 0 : json["postcode"],
    "street": !exists(json, "street") ? void 0 : json["street"],
    "subBuilding": !exists(json, "sub_building") ? void 0 : json["sub_building"]
  };
}
function ModelsUpdateSubscriptionPatientVendorRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "address_line_1": value.addressLine1,
    "address_line_2": value.addressLine2,
    "birth_date": value.birthDate,
    "building_name": value.buildingName,
    "building_number": value.buildingNumber,
    "city": value.city,
    "country": value.country,
    "country_iso_2": value.countryIso2,
    "email": value.email,
    "external_id": value.externalId,
    "first_name": value.firstName,
    "gender": value.gender,
    "label": value.label,
    "last_name": value.lastName,
    "mobile_number": value.mobileNumber,
    "postcode": value.postcode,
    "street": value.street,
    "sub_building": value.subBuilding
  };
}

// src/generated/orchestrators/plans/models/ModelsUpsertFeatureFlagRequest.ts
function instanceOfModelsUpsertFeatureFlagRequest(value) {
  let isInstance = true;
  isInstance = isInstance && "featureFlagId" in value;
  return isInstance;
}
function ModelsUpsertFeatureFlagRequestFromJSON(json) {
  return ModelsUpsertFeatureFlagRequestFromJSONTyped(json);
}
function ModelsUpsertFeatureFlagRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "featureFlagId": json["feature_flag_id"],
    "value": !exists(json, "value") ? void 0 : json["value"]
  };
}
function ModelsUpsertFeatureFlagRequestToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "feature_flag_id": value.featureFlagId,
    "value": value.value
  };
}

// src/generated/orchestrators/plans/models/ThttpErrResponse.ts
function instanceOfThttpErrResponse(value) {
  let isInstance = true;
  return isInstance;
}
function ThttpErrResponseFromJSON(json) {
  return ThttpErrResponseFromJSONTyped(json);
}
function ThttpErrResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === void 0 || json === null) {
    return json;
  }
  return {
    "code": !exists(json, "code") ? void 0 : json["code"],
    "message": !exists(json, "message") ? void 0 : json["message"]
  };
}
function ThttpErrResponseToJSON(value) {
  if (value === void 0) {
    return void 0;
  }
  if (value === null) {
    return null;
  }
  return {
    "code": value.code,
    "message": value.message
  };
}

// src/generated/orchestrators/plans/apis/DirectdebitApi.ts
var DirectdebitApi = class extends BaseAPI {
  /**
   * Onboards a directdebit customer. Required before creating a subscription.
   * Onboards a directdebit customer.
   */
  async apiV1OrchestratorPlansDirectdebitCustomersCustomerIdPostRaw(requestParameters, initOverrides) {
    if (requestParameters.customerId === null || requestParameters.customerId === void 0) {
      throw new RequiredError("customerId", "Required parameter requestParameters.customerId was null or undefined when calling apiV1OrchestratorPlansDirectdebitCustomersCustomerIdPost.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansDirectdebitCustomersCustomerIdPost.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/directdebit/customers/{customer_id}`.replace(`{${"customer_id"}}`, encodeURIComponent(String(requestParameters.customerId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsOnboardDirectDebitCustomerRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsOnboardDirectDebitCustomerResponseFromJSON(jsonValue));
  }
  /**
   * Onboards a directdebit customer. Required before creating a subscription.
   * Onboards a directdebit customer.
   */
  async apiV1OrchestratorPlansDirectdebitCustomersCustomerIdPost(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansDirectdebitCustomersCustomerIdPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Creates a directdebit subscription. A pending subscription is then returned.
   * Creates a directdebit subscription.
   */
  async apiV1OrchestratorPlansDirectdebitSubscriptionsPostRaw(requestParameters, initOverrides) {
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansDirectdebitSubscriptionsPost.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/directdebit/subscriptions`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsCreateDirectDebitSubscriptionRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsDirectDebitSubscriptionFromJSON(jsonValue));
  }
  /**
   * Creates a directdebit subscription. A pending subscription is then returned.
   * Creates a directdebit subscription.
   */
  async apiV1OrchestratorPlansDirectdebitSubscriptionsPost(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansDirectdebitSubscriptionsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
};

// src/generated/orchestrators/plans/apis/PlansApi.ts
var PlansApi = class extends BaseAPI {
  /**
   * Returns required plan data to start the self application process.
   * get public plan data.
   */
  async apiV1OrchestratorPlansPlansApplicationsApplicationKeyGetRaw(requestParameters, initOverrides) {
    if (requestParameters.applicationKey === null || requestParameters.applicationKey === void 0) {
      throw new RequiredError("applicationKey", "Required parameter requestParameters.applicationKey was null or undefined when calling apiV1OrchestratorPlansPlansApplicationsApplicationKeyGet.");
    }
    const queryParameters = {};
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/plans/applications/{application_key}`.replace(`{${"application_key"}}`, encodeURIComponent(String(requestParameters.applicationKey))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsGetPlanPublicResponseFromJSON(jsonValue));
  }
  /**
   * Returns required plan data to start the self application process.
   * get public plan data.
   */
  async apiV1OrchestratorPlansPlansApplicationsApplicationKeyGet(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansPlansApplicationsApplicationKeyGetRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Returns the create plan images array.
   * Returns the create plan images array.
   */
  async apiV1OrchestratorPlansPlansCreateImagesGetRaw(requestParameters, initOverrides) {
    if (requestParameters.authorization === null || requestParameters.authorization === void 0) {
      throw new RequiredError("authorization", "Required parameter requestParameters.authorization was null or undefined when calling apiV1OrchestratorPlansPlansCreateImagesGet.");
    }
    const queryParameters = {};
    const headerParameters = {};
    if (requestParameters.authorization !== void 0 && requestParameters.authorization !== null) {
      headerParameters["Authorization"] = String(requestParameters.authorization);
    }
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/plans/create/images`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new JSONApiResponse(response);
  }
  /**
   * Returns the create plan images array.
   * Returns the create plan images array.
   */
  async apiV1OrchestratorPlansPlansCreateImagesGet(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansPlansCreateImagesGetRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Creates a plan.
   * Creates a plan.
   */
  async apiV1OrchestratorPlansPlansCreatePostRaw(requestParameters, initOverrides) {
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansPlansCreatePost.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/plans/create`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsCreatePlanRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsCreatePlanResponseFromJSON(jsonValue));
  }
  /**
   * Creates a plan.
   * Creates a plan.
   */
  async apiV1OrchestratorPlansPlansCreatePost(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansPlansCreatePostRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Soft deletes the plan.
   * Deletes the plan.
   */
  async apiV1OrchestratorPlansPlansPlanIdDeleteRaw(requestParameters, initOverrides) {
    if (requestParameters.planId === null || requestParameters.planId === void 0) {
      throw new RequiredError("planId", "Required parameter requestParameters.planId was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdDelete.");
    }
    const queryParameters = {};
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/plans/{plan_id}`.replace(`{${"plan_id"}}`, encodeURIComponent(String(requestParameters.planId))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new VoidApiResponse(response);
  }
  /**
   * Soft deletes the plan.
   * Deletes the plan.
   */
  async apiV1OrchestratorPlansPlansPlanIdDelete(requestParameters, initOverrides) {
    await this.apiV1OrchestratorPlansPlansPlanIdDeleteRaw(requestParameters, initOverrides);
  }
  /**
   * Returns the plan data.
   * Returns the plan data.
   */
  async apiV1OrchestratorPlansPlansPlanIdGetRaw(requestParameters, initOverrides) {
    if (requestParameters.vendorId === null || requestParameters.vendorId === void 0) {
      throw new RequiredError("vendorId", "Required parameter requestParameters.vendorId was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdGet.");
    }
    if (requestParameters.planId === null || requestParameters.planId === void 0) {
      throw new RequiredError("planId", "Required parameter requestParameters.planId was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdGet.");
    }
    const queryParameters = {};
    if (requestParameters.vendorId !== void 0) {
      queryParameters["vendor_id"] = requestParameters.vendorId;
    }
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/plans/{plan_id}`.replace(`{${"plan_id"}}`, encodeURIComponent(String(requestParameters.planId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsGetPlanResponseFromJSON(jsonValue));
  }
  /**
   * Returns the plan data.
   * Returns the plan data.
   */
  async apiV1OrchestratorPlansPlansPlanIdGet(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansPlansPlanIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Updates a plan.
   * Updates a plan.
   */
  async apiV1OrchestratorPlansPlansPlanIdPatchRaw(requestParameters, initOverrides) {
    if (requestParameters.planId === null || requestParameters.planId === void 0) {
      throw new RequiredError("planId", "Required parameter requestParameters.planId was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdPatch.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdPatch.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/plans/{plan_id}`.replace(`{${"plan_id"}}`, encodeURIComponent(String(requestParameters.planId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsUpdatePlanRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsUpdatePlanResponseFromJSON(jsonValue));
  }
  /**
   * Updates a plan.
   * Updates a plan.
   */
  async apiV1OrchestratorPlansPlansPlanIdPatch(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansPlansPlanIdPatchRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Updates a plan.
   * Updates a plan.
   */
  async apiV1OrchestratorPlansPlansPlanIdStatusPatchRaw(requestParameters, initOverrides) {
    if (requestParameters.planId === null || requestParameters.planId === void 0) {
      throw new RequiredError("planId", "Required parameter requestParameters.planId was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdStatusPatch.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdStatusPatch.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/plans/{plan_id}/status`.replace(`{${"plan_id"}}`, encodeURIComponent(String(requestParameters.planId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsChangePlanStatusRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsChangePlanStatusResponseFromJSON(jsonValue));
  }
  /**
   * Updates a plan.
   * Updates a plan.
   */
  async apiV1OrchestratorPlansPlansPlanIdStatusPatch(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansPlansPlanIdStatusPatchRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Selects plans.
   * Selects plans list.
   */
  async apiV1OrchestratorPlansPlansSelectPostRaw(requestParameters, initOverrides) {
    if (requestParameters.vendorId === null || requestParameters.vendorId === void 0) {
      throw new RequiredError("vendorId", "Required parameter requestParameters.vendorId was null or undefined when calling apiV1OrchestratorPlansPlansSelectPost.");
    }
    const queryParameters = {};
    if (requestParameters.vendorId !== void 0) {
      queryParameters["vendor_id"] = requestParameters.vendorId;
    }
    if (requestParameters.limit !== void 0) {
      queryParameters["limit"] = requestParameters.limit;
    }
    if (requestParameters.offset !== void 0) {
      queryParameters["offset"] = requestParameters.offset;
    }
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/plans/select`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsSelectPlansResponseFromJSON(jsonValue));
  }
  /**
   * Selects plans.
   * Selects plans list.
   */
  async apiV1OrchestratorPlansPlansSelectPost(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansPlansSelectPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Gets a new account link.
   * Gets a new account link.
   */
  async apiV1OrchestratorPlansStripeAccountsLinkGetRaw(initOverrides) {
    const queryParameters = {};
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/stripe/accounts/link`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsAccountLinkFromJSON(jsonValue));
  }
  /**
   * Gets a new account link.
   * Gets a new account link.
   */
  async apiV1OrchestratorPlansStripeAccountsLinkGet(initOverrides) {
    const response = await this.apiV1OrchestratorPlansStripeAccountsLinkGetRaw(initOverrides);
    return await response.value();
  }
  /**
   * Returns the vendor data.
   * Returns the vendor data.
   */
  async apiV1OrchestratorPlansVendorsGetRaw(requestParameters, initOverrides) {
    if (requestParameters.authorization === null || requestParameters.authorization === void 0) {
      throw new RequiredError("authorization", "Required parameter requestParameters.authorization was null or undefined when calling apiV1OrchestratorPlansVendorsGet.");
    }
    if (requestParameters.legacyId === null || requestParameters.legacyId === void 0) {
      throw new RequiredError("legacyId", "Required parameter requestParameters.legacyId was null or undefined when calling apiV1OrchestratorPlansVendorsGet.");
    }
    const queryParameters = {};
    if (requestParameters.legacyId !== void 0) {
      queryParameters["legacy_id"] = requestParameters.legacyId;
    }
    if (requestParameters.resync !== void 0) {
      queryParameters["resync"] = requestParameters.resync;
    }
    const headerParameters = {};
    if (requestParameters.authorization !== void 0 && requestParameters.authorization !== null) {
      headerParameters["Authorization"] = String(requestParameters.authorization);
    }
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/vendors`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsGetVendorResponseFromJSON(jsonValue));
  }
  /**
   * Returns the vendor data.
   * Returns the vendor data.
   */
  async apiV1OrchestratorPlansVendorsGet(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansVendorsGetRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Returns the vendor data with public plans.
   * Returns the vendor data.
   */
  async apiV1OrchestratorPlansVendorsVendorSlugGetRaw(requestParameters, initOverrides) {
    if (requestParameters.vendorSlug === null || requestParameters.vendorSlug === void 0) {
      throw new RequiredError("vendorSlug", "Required parameter requestParameters.vendorSlug was null or undefined when calling apiV1OrchestratorPlansVendorsVendorSlugGet.");
    }
    const queryParameters = {};
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/vendors/{vendor_slug}`.replace(`{${"vendor_slug"}}`, encodeURIComponent(String(requestParameters.vendorSlug))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsGetVendorPublicResponseFromJSON(jsonValue));
  }
  /**
   * Returns the vendor data with public plans.
   * Returns the vendor data.
   */
  async apiV1OrchestratorPlansVendorsVendorSlugGet(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansVendorsVendorSlugGetRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Returns required plan data to start the self application process.
   * get public plan data.
   */
  async apiV1OrchestratorPlansVendorsVendorSlugPlansPlanSlugGetRaw(requestParameters, initOverrides) {
    if (requestParameters.vendorSlug === null || requestParameters.vendorSlug === void 0) {
      throw new RequiredError("vendorSlug", "Required parameter requestParameters.vendorSlug was null or undefined when calling apiV1OrchestratorPlansVendorsVendorSlugPlansPlanSlugGet.");
    }
    if (requestParameters.planSlug === null || requestParameters.planSlug === void 0) {
      throw new RequiredError("planSlug", "Required parameter requestParameters.planSlug was null or undefined when calling apiV1OrchestratorPlansVendorsVendorSlugPlansPlanSlugGet.");
    }
    const queryParameters = {};
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/vendors/{vendor_slug}/plans/{plan_slug}`.replace(`{${"vendor_slug"}}`, encodeURIComponent(String(requestParameters.vendorSlug))).replace(`{${"plan_slug"}}`, encodeURIComponent(String(requestParameters.planSlug))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsGetPlanPublicResponseFromJSON(jsonValue));
  }
  /**
   * Returns required plan data to start the self application process.
   * get public plan data.
   */
  async apiV1OrchestratorPlansVendorsVendorSlugPlansPlanSlugGet(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansVendorsVendorSlugPlansPlanSlugGetRaw(requestParameters, initOverrides);
    return await response.value();
  }
};

// src/generated/orchestrators/plans/apis/StripeApi.ts
var StripeApi = class extends BaseAPI {
  /**
   * Onboards a stripe customer. Creates a pending subscription and a setup intent.
   * Onboards a stripe customer.
   */
  async apiV1OrchestratorPlansStripeCustomersCustomerIdPostRaw(requestParameters, initOverrides) {
    if (requestParameters.customerId === null || requestParameters.customerId === void 0) {
      throw new RequiredError("customerId", "Required parameter requestParameters.customerId was null or undefined when calling apiV1OrchestratorPlansStripeCustomersCustomerIdPost.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansStripeCustomersCustomerIdPost.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/stripe/customers/{customer_id}`.replace(`{${"customer_id"}}`, encodeURIComponent(String(requestParameters.customerId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsOnboardStripeCustomerRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsOnboardStripeCustomerResponseFromJSON(jsonValue));
  }
  /**
   * Onboards a stripe customer. Creates a pending subscription and a setup intent.
   * Onboards a stripe customer.
   */
  async apiV1OrchestratorPlansStripeCustomersCustomerIdPost(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansStripeCustomersCustomerIdPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Create a stripe subscription if an active mandate exists or a pending subscription if it doesn\'t.
   * Create a stripe subscription.
   */
  async apiV1OrchestratorPlansStripeCustomersCustomerIdSubscriptionsPostRaw(requestParameters, initOverrides) {
    if (requestParameters.customerId === null || requestParameters.customerId === void 0) {
      throw new RequiredError("customerId", "Required parameter requestParameters.customerId was null or undefined when calling apiV1OrchestratorPlansStripeCustomersCustomerIdSubscriptionsPost.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansStripeCustomersCustomerIdSubscriptionsPost.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/stripe/customers/{customer_id}/subscriptions`.replace(`{${"customer_id"}}`, encodeURIComponent(String(requestParameters.customerId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsCreateStripeSubscriptionRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsCreateStripeSubscriptionRequestFromJSON(jsonValue));
  }
  /**
   * Create a stripe subscription if an active mandate exists or a pending subscription if it doesn\'t.
   * Create a stripe subscription.
   */
  async apiV1OrchestratorPlansStripeCustomersCustomerIdSubscriptionsPost(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansStripeCustomersCustomerIdSubscriptionsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
};

// src/generated/orchestrators/plans/apis/SubscriptionsApi.ts
var SubscriptionsApi = class extends BaseAPI {
  /**
   * Update subscription patient for existing subscription.
   * Update the subscription patient.
   */
  async apiV1OrchestratorCustomerPlansSubscriptionsSubscriptionIdPatientPatchRaw(requestParameters, initOverrides) {
    if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === void 0) {
      throw new RequiredError("subscriptionId", "Required parameter requestParameters.subscriptionId was null or undefined when calling apiV1OrchestratorCustomerPlansSubscriptionsSubscriptionIdPatientPatch.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorCustomerPlansSubscriptionsSubscriptionIdPatientPatch.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/customer/plans/subscriptions/{subscription_id}/patient`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsUpdateSubscriptionPatientConsumerRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new VoidApiResponse(response);
  }
  /**
   * Update subscription patient for existing subscription.
   * Update the subscription patient.
   */
  async apiV1OrchestratorCustomerPlansSubscriptionsSubscriptionIdPatientPatch(requestParameters, initOverrides) {
    await this.apiV1OrchestratorCustomerPlansSubscriptionsSubscriptionIdPatientPatchRaw(requestParameters, initOverrides);
  }
  /**
   * Returns required membership data to start the signup process.
   * get the invite to a plan membership data.
   */
  async apiV1OrchestratorPlansPlansInviteTokenGetRaw(requestParameters, initOverrides) {
    if (requestParameters.token === null || requestParameters.token === void 0) {
      throw new RequiredError("token", "Required parameter requestParameters.token was null or undefined when calling apiV1OrchestratorPlansPlansInviteTokenGet.");
    }
    const queryParameters = {};
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/plans/invite/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsGetPlansSubscriptionInviteResponseFromJSON(jsonValue));
  }
  /**
   * Returns required membership data to start the signup process.
   * get the invite to a plan membership data.
   */
  async apiV1OrchestratorPlansPlansInviteTokenGet(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansPlansInviteTokenGetRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Create membership.
   * Create membership.
   */
  async apiV1OrchestratorPlansPlansPlanIdApplyPostRaw(requestParameters, initOverrides) {
    if (requestParameters.planId === null || requestParameters.planId === void 0) {
      throw new RequiredError("planId", "Required parameter requestParameters.planId was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdApplyPost.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdApplyPost.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/plans/{plan_id}/apply`.replace(`{${"plan_id"}}`, encodeURIComponent(String(requestParameters.planId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsApplyPlansSubscriptionRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsApplyPlansSubscriptionResponseFromJSON(jsonValue));
  }
  /**
   * Create membership.
   * Create membership.
   */
  async apiV1OrchestratorPlansPlansPlanIdApplyPost(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansPlansPlanIdApplyPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Accept the membership invite.
   * Accept the membership invite.
   */
  async apiV1OrchestratorPlansPlansPlanIdInviteInviteIdPatchRaw(requestParameters, initOverrides) {
    if (requestParameters.planId === null || requestParameters.planId === void 0) {
      throw new RequiredError("planId", "Required parameter requestParameters.planId was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdInviteInviteIdPatch.");
    }
    if (requestParameters.inviteId === null || requestParameters.inviteId === void 0) {
      throw new RequiredError("inviteId", "Required parameter requestParameters.inviteId was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdInviteInviteIdPatch.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdInviteInviteIdPatch.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/plans/{plan_id}/invite/{invite_id}`.replace(`{${"plan_id"}}`, encodeURIComponent(String(requestParameters.planId))).replace(`{${"invite_id"}}`, encodeURIComponent(String(requestParameters.inviteId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsAcceptSubscriptionInviteRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new VoidApiResponse(response);
  }
  /**
   * Accept the membership invite.
   * Accept the membership invite.
   */
  async apiV1OrchestratorPlansPlansPlanIdInviteInviteIdPatch(requestParameters, initOverrides) {
    await this.apiV1OrchestratorPlansPlansPlanIdInviteInviteIdPatchRaw(requestParameters, initOverrides);
  }
  /**
   * Updates a patient to subscription
   * Updates a patient to subscription
   */
  async apiV1OrchestratorPlansPlansPlanIdInviteInviteIdPatientPatchRaw(requestParameters, initOverrides) {
    if (requestParameters.planId === null || requestParameters.planId === void 0) {
      throw new RequiredError("planId", "Required parameter requestParameters.planId was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdInviteInviteIdPatientPatch.");
    }
    if (requestParameters.inviteId === null || requestParameters.inviteId === void 0) {
      throw new RequiredError("inviteId", "Required parameter requestParameters.inviteId was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdInviteInviteIdPatientPatch.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdInviteInviteIdPatientPatch.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/plans/{plan_id}/invite/{invite_id}/patient`.replace(`{${"plan_id"}}`, encodeURIComponent(String(requestParameters.planId))).replace(`{${"invite_id"}}`, encodeURIComponent(String(requestParameters.inviteId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsPlanSubscriptionInviteUpdatePatientRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new VoidApiResponse(response);
  }
  /**
   * Updates a patient to subscription
   * Updates a patient to subscription
   */
  async apiV1OrchestratorPlansPlansPlanIdInviteInviteIdPatientPatch(requestParameters, initOverrides) {
    await this.apiV1OrchestratorPlansPlansPlanIdInviteInviteIdPatientPatchRaw(requestParameters, initOverrides);
  }
  /**
   * Register a customer to a plan subscription on the legacy platform.
   * Register a customer to a plan subscription on the legacy platform.
   */
  async apiV1OrchestratorPlansPlansPlanIdInviteInviteIdRegisterPostRaw(requestParameters, initOverrides) {
    if (requestParameters.planId === null || requestParameters.planId === void 0) {
      throw new RequiredError("planId", "Required parameter requestParameters.planId was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdInviteInviteIdRegisterPost.");
    }
    if (requestParameters.inviteId === null || requestParameters.inviteId === void 0) {
      throw new RequiredError("inviteId", "Required parameter requestParameters.inviteId was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdInviteInviteIdRegisterPost.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdInviteInviteIdRegisterPost.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/plans/{plan_id}/invite/{invite_id}/register`.replace(`{${"plan_id"}}`, encodeURIComponent(String(requestParameters.planId))).replace(`{${"invite_id"}}`, encodeURIComponent(String(requestParameters.inviteId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsRegisterCustomerToJSON(requestParameters.body)
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsRegisterCustomerResponseFromJSON(jsonValue));
  }
  /**
   * Register a customer to a plan subscription on the legacy platform.
   * Register a customer to a plan subscription on the legacy platform.
   */
  async apiV1OrchestratorPlansPlansPlanIdInviteInviteIdRegisterPost(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansPlansPlanIdInviteInviteIdRegisterPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Invites a member to a subscription/plan membership.
   * CreateInvite a member to a plan membership.
   */
  async apiV1OrchestratorPlansPlansPlanIdInvitePostRaw(requestParameters, initOverrides) {
    if (requestParameters.planId === null || requestParameters.planId === void 0) {
      throw new RequiredError("planId", "Required parameter requestParameters.planId was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdInvitePost.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansPlansPlanIdInvitePost.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/plans/{plan_id}/invite`.replace(`{${"plan_id"}}`, encodeURIComponent(String(requestParameters.planId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsCreateInvitePlansSubscriptionRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsCreateInvitePlansSubscriptionResponseFromJSON(jsonValue));
  }
  /**
   * Invites a member to a subscription/plan membership.
   * CreateInvite a member to a plan membership.
   */
  async apiV1OrchestratorPlansPlansPlanIdInvitePost(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansPlansPlanIdInvitePostRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Generates a new subscription checkout session for a subscription and redirects the user.
   * Initiates the direct debit update redirect.
   */
  async apiV1OrchestratorPlansSubscriptionsRecreateDirectDebitGetRaw(requestParameters, initOverrides) {
    if (requestParameters.token === null || requestParameters.token === void 0) {
      throw new RequiredError("token", "Required parameter requestParameters.token was null or undefined when calling apiV1OrchestratorPlansSubscriptionsRecreateDirectDebitGet.");
    }
    const queryParameters = {};
    if (requestParameters.token !== void 0) {
      queryParameters["token"] = requestParameters.token;
    }
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/subscriptions/recreate-direct-debit`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new VoidApiResponse(response);
  }
  /**
   * Generates a new subscription checkout session for a subscription and redirects the user.
   * Initiates the direct debit update redirect.
   */
  async apiV1OrchestratorPlansSubscriptionsRecreateDirectDebitGet(requestParameters, initOverrides) {
    await this.apiV1OrchestratorPlansSubscriptionsRecreateDirectDebitGetRaw(requestParameters, initOverrides);
  }
  /**
   * Selects customer subscriptions with some additional data for the subscription listing.
   * Selects subscriptions list.
   */
  async apiV1OrchestratorPlansSubscriptionsSelectGetRaw(requestParameters, initOverrides) {
    const queryParameters = {};
    if (requestParameters.limit !== void 0) {
      queryParameters["limit"] = requestParameters.limit;
    }
    if (requestParameters.offset !== void 0) {
      queryParameters["offset"] = requestParameters.offset;
    }
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/subscriptions/select`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsSelectSubscriptionsResponseFromJSON(jsonValue));
  }
  /**
   * Selects customer subscriptions with some additional data for the subscription listing.
   * Selects subscriptions list.
   */
  async apiV1OrchestratorPlansSubscriptionsSelectGet(requestParameters = {}, initOverrides) {
    const response = await this.apiV1OrchestratorPlansSubscriptionsSelectGetRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Selects subscriptions with some additional data for the subscription listing based on filters.
   * Selects subscriptions list.
   */
  async apiV1OrchestratorPlansSubscriptionsSelectPostRaw(requestParameters, initOverrides) {
    if (requestParameters.vendorId === null || requestParameters.vendorId === void 0) {
      throw new RequiredError("vendorId", "Required parameter requestParameters.vendorId was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSelectPost.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSelectPost.");
    }
    const queryParameters = {};
    if (requestParameters.vendorId !== void 0) {
      queryParameters["vendor_id"] = requestParameters.vendorId;
    }
    if (requestParameters.limit !== void 0) {
      queryParameters["limit"] = requestParameters.limit;
    }
    if (requestParameters.offset !== void 0) {
      queryParameters["offset"] = requestParameters.offset;
    }
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/subscriptions/select`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsSelectSubscriptionsRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsSelectSubscriptionsResponseFromJSON(jsonValue));
  }
  /**
   * Selects subscriptions with some additional data for the subscription listing based on filters.
   * Selects subscriptions list.
   */
  async apiV1OrchestratorPlansSubscriptionsSelectPost(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansSubscriptionsSelectPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Cancels the existing subscription.
   * Cancels subscription.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdCancelPatchRaw(requestParameters, initOverrides) {
    if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === void 0) {
      throw new RequiredError("subscriptionId", "Required parameter requestParameters.subscriptionId was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSubscriptionIdCancelPatch.");
    }
    const queryParameters = {};
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/subscriptions/{subscription_id}/cancel`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsCancelSubscriptionResponseFromJSON(jsonValue));
  }
  /**
   * Cancels the existing subscription.
   * Cancels subscription.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdCancelPatch(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansSubscriptionsSubscriptionIdCancelPatchRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Registers a claim for the subscription.
   * Creates subscription claim.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdClaimPatchRaw(requestParameters, initOverrides) {
    if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === void 0) {
      throw new RequiredError("subscriptionId", "Required parameter requestParameters.subscriptionId was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSubscriptionIdClaimPatch.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSubscriptionIdClaimPatch.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/subscriptions/{subscription_id}/claim`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsRegisterClaimRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new VoidApiResponse(response);
  }
  /**
   * Registers a claim for the subscription.
   * Creates subscription claim.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdClaimPatch(requestParameters, initOverrides) {
    await this.apiV1OrchestratorPlansSubscriptionsSubscriptionIdClaimPatchRaw(requestParameters, initOverrides);
  }
  /**
   * Updates the subscription with the specified clinician.
   * Change the subscription clinician.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdClinicianPatchRaw(requestParameters, initOverrides) {
    if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === void 0) {
      throw new RequiredError("subscriptionId", "Required parameter requestParameters.subscriptionId was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSubscriptionIdClinicianPatch.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSubscriptionIdClinicianPatch.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/subscriptions/{subscription_id}/clinician`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsChangeSubscriptionClinicianRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new VoidApiResponse(response);
  }
  /**
   * Updates the subscription with the specified clinician.
   * Change the subscription clinician.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdClinicianPatch(requestParameters, initOverrides) {
    await this.apiV1OrchestratorPlansSubscriptionsSubscriptionIdClinicianPatchRaw(requestParameters, initOverrides);
  }
  /**
   * Returns the subscription data with extensions.
   * Returns the subscription data.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdGetRaw(requestParameters, initOverrides) {
    if (requestParameters.vendorId === null || requestParameters.vendorId === void 0) {
      throw new RequiredError("vendorId", "Required parameter requestParameters.vendorId was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSubscriptionIdGet.");
    }
    if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === void 0) {
      throw new RequiredError("subscriptionId", "Required parameter requestParameters.subscriptionId was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSubscriptionIdGet.");
    }
    const queryParameters = {};
    if (requestParameters.vendorId !== void 0) {
      queryParameters["vendor_id"] = requestParameters.vendorId;
    }
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/subscriptions/{subscription_id}`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsGetSubscriptionResponseFromJSON(jsonValue));
  }
  /**
   * Returns the subscription data with extensions.
   * Returns the subscription data.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdGet(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansSubscriptionsSubscriptionIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Resend the membership invite.
   * Resend the membership invite.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdInviteResendPostRaw(requestParameters, initOverrides) {
    if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === void 0) {
      throw new RequiredError("subscriptionId", "Required parameter requestParameters.subscriptionId was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSubscriptionIdInviteResendPost.");
    }
    const queryParameters = {};
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/subscriptions/{subscription_id}/invite/resend`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new VoidApiResponse(response);
  }
  /**
   * Resend the membership invite.
   * Resend the membership invite.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdInviteResendPost(requestParameters, initOverrides) {
    await this.apiV1OrchestratorPlansSubscriptionsSubscriptionIdInviteResendPostRaw(requestParameters, initOverrides);
  }
  /**
   * Swap subscription plan for existing subscription.
   * Swap the subscription plan.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdPlanPatchRaw(requestParameters, initOverrides) {
    if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === void 0) {
      throw new RequiredError("subscriptionId", "Required parameter requestParameters.subscriptionId was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSubscriptionIdPlanPatch.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSubscriptionIdPlanPatch.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/subscriptions/{subscription_id}/plan`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsSwapSubscriptionPlanRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new VoidApiResponse(response);
  }
  /**
   * Swap subscription plan for existing subscription.
   * Swap the subscription plan.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdPlanPatch(requestParameters, initOverrides) {
    await this.apiV1OrchestratorPlansSubscriptionsSubscriptionIdPlanPatchRaw(requestParameters, initOverrides);
  }
  /**
   * Send email to customer to recreate their direct debit.
   * Initiate recreate direct debit for a membership.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdRecreateDirectDebitPostRaw(requestParameters, initOverrides) {
    if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === void 0) {
      throw new RequiredError("subscriptionId", "Required parameter requestParameters.subscriptionId was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSubscriptionIdRecreateDirectDebitPost.");
    }
    const queryParameters = {};
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/subscriptions/{subscription_id}/recreate_direct_debit`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new VoidApiResponse(response);
  }
  /**
   * Send email to customer to recreate their direct debit.
   * Initiate recreate direct debit for a membership.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdRecreateDirectDebitPost(requestParameters, initOverrides) {
    await this.apiV1OrchestratorPlansSubscriptionsSubscriptionIdRecreateDirectDebitPostRaw(requestParameters, initOverrides);
  }
  /**
   * Updates the subscription with the specified status.
   * Change the subscription status.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdStatusPatchRaw(requestParameters, initOverrides) {
    if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === void 0) {
      throw new RequiredError("subscriptionId", "Required parameter requestParameters.subscriptionId was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSubscriptionIdStatusPatch.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSubscriptionIdStatusPatch.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/subscriptions/{subscription_id}/status`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsChangeSubscriptionStatusRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsGetSubscriptionResponseFromJSON(jsonValue));
  }
  /**
   * Updates the subscription with the specified status.
   * Change the subscription status.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdStatusPatch(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansSubscriptionsSubscriptionIdStatusPatchRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Returns the subscription data with extensions.
   * Returns the subscription data.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdSummaryGetRaw(requestParameters, initOverrides) {
    if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === void 0) {
      throw new RequiredError("subscriptionId", "Required parameter requestParameters.subscriptionId was null or undefined when calling apiV1OrchestratorPlansSubscriptionsSubscriptionIdSummaryGet.");
    }
    const queryParameters = {};
    const headerParameters = {};
    const response = await this.request({
      path: `/api/v1/orchestrator/plans/subscriptions/{subscription_id}/summary`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsGetSubscriptionSummaryResponseFromJSON(jsonValue));
  }
  /**
   * Returns the subscription data with extensions.
   * Returns the subscription data.
   */
  async apiV1OrchestratorPlansSubscriptionsSubscriptionIdSummaryGet(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorPlansSubscriptionsSubscriptionIdSummaryGetRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Update subscription patient for existing subscription.
   * Update the subscription patient.
   */
  async apiV1OrchestratorVendorPlansSubscriptionsSubscriptionIdPatientPatchRaw(requestParameters, initOverrides) {
    if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === void 0) {
      throw new RequiredError("subscriptionId", "Required parameter requestParameters.subscriptionId was null or undefined when calling apiV1OrchestratorVendorPlansSubscriptionsSubscriptionIdPatientPatch.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorVendorPlansSubscriptionsSubscriptionIdPatientPatch.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/vendor/plans/subscriptions/{subscription_id}/patient`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsUpdateSubscriptionPatientVendorRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new VoidApiResponse(response);
  }
  /**
   * Update subscription patient for existing subscription.
   * Update the subscription patient.
   */
  async apiV1OrchestratorVendorPlansSubscriptionsSubscriptionIdPatientPatch(requestParameters, initOverrides) {
    await this.apiV1OrchestratorVendorPlansSubscriptionsSubscriptionIdPatientPatchRaw(requestParameters, initOverrides);
  }
};

// src/generated/orchestrators/plans/apis/VendorsApi.ts
var VendorsApi = class extends BaseAPI {
  /**
   * Creates a feature flag.
   * Creates a feature flag.
   */
  async apiV1OrchestratorVendorsFeatureflagsCreatePostRaw(requestParameters, initOverrides) {
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorVendorsFeatureflagsCreatePost.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/vendors/featureflags/create`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsCreateFeatureFlagRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new VoidApiResponse(response);
  }
  /**
   * Creates a feature flag.
   * Creates a feature flag.
   */
  async apiV1OrchestratorVendorsFeatureflagsCreatePost(requestParameters, initOverrides) {
    await this.apiV1OrchestratorVendorsFeatureflagsCreatePostRaw(requestParameters, initOverrides);
  }
  /**
   * Selects the feature flags for a vendor.
   * Selects the feature flags for a vendor.
   */
  async apiV1OrchestratorVendorsFeatureflagsSelectPostRaw(requestParameters, initOverrides) {
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorVendorsFeatureflagsSelectPost.");
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/vendors/featureflags/select`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsSelectFeatureFlagsRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsVendorFeatureFlagFromJSON(jsonValue));
  }
  /**
   * Selects the feature flags for a vendor.
   * Selects the feature flags for a vendor.
   */
  async apiV1OrchestratorVendorsFeatureflagsSelectPost(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorVendorsFeatureflagsSelectPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   * Inserts or updates the vendor a feature flag.
   * Inserts or updates the vendor a feature flag.
   */
  async apiV1OrchestratorVendorsFeatureflagsUpsertPatchRaw(requestParameters, initOverrides) {
    if (requestParameters.vendorId === null || requestParameters.vendorId === void 0) {
      throw new RequiredError("vendorId", "Required parameter requestParameters.vendorId was null or undefined when calling apiV1OrchestratorVendorsFeatureflagsUpsertPatch.");
    }
    if (requestParameters.body === null || requestParameters.body === void 0) {
      throw new RequiredError("body", "Required parameter requestParameters.body was null or undefined when calling apiV1OrchestratorVendorsFeatureflagsUpsertPatch.");
    }
    const queryParameters = {};
    if (requestParameters.vendorId !== void 0) {
      queryParameters["vendor_id"] = requestParameters.vendorId;
    }
    const headerParameters = {};
    headerParameters["Content-Type"] = "application/json";
    const response = await this.request({
      path: `/api/v1/orchestrator/vendors/featureflags/upsert`,
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ModelsUpsertFeatureFlagRequestToJSON(requestParameters.body)
    }, initOverrides);
    return new JSONApiResponse(response, (jsonValue) => ModelsVendorFeatureFlagFromJSON(jsonValue));
  }
  /**
   * Inserts or updates the vendor a feature flag.
   * Inserts or updates the vendor a feature flag.
   */
  async apiV1OrchestratorVendorsFeatureflagsUpsertPatch(requestParameters, initOverrides) {
    const response = await this.apiV1OrchestratorVendorsFeatureflagsUpsertPatchRaw(requestParameters, initOverrides);
    return await response.value();
  }
};

// src/index.ts
var PlatformApi = class {
  plansorchestrator;
  constructor(config) {
    this.plansorchestrator = {
      plans: new PlansApi(
        new Configuration(config)
      ),
      subscriptions: new SubscriptionsApi(
        new Configuration(config)
      ),
      directdebit: new DirectdebitApi(
        new Configuration(config)
      )
    };
  }
};
var PlatformApiError = class extends Error {
  response;
  constructor(response) {
    super(response.message);
    this.response = response;
  }
};

export { PlatformApi, PlatformApiError, plans_exports as plansorchestrator };
