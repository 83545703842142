import { CheckIcon } from '@heroicons/react/20/solid'
import { plansorchestrator } from '@tabeo/platform-api-client'
import { format } from '@tabeo/sharpei/utils/currency'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

// Update interface to include perks
interface ComparisonTableProps {
  currentPlan: plansorchestrator.ModelsPlan
  selectedPlan: plansorchestrator.ModelsPlan
  subscriptionData: plansorchestrator.ModelsGetSubscriptionAggregate
}

type Treatment = plansorchestrator.ModelsTreatment
type Perk = plansorchestrator.ModelsPerk

const ComparisonTable = ({
  currentPlan,
  selectedPlan,
  subscriptionData,
}: ComparisonTableProps) => {
  const { t } = useTranslation()

  // Get all unique treatments from both plans for comparison
  const allTreatments = useMemo(() => {
    const treatmentsMap = new Map<number, Treatment>()

    // Add treatments from selected plan
    const selectedPlanTreatments = selectedPlan?.treatments || []
    selectedPlanTreatments.forEach((item: Treatment) => {
      if (item?.legacyId) {
        treatmentsMap.set(item.legacyId, item)
      }
    })

    // Add treatments from current plan
    const currentPlanTreatments = currentPlan?.treatments || []
    currentPlanTreatments.forEach((item: Treatment) => {
      if (item?.legacyId) {
        treatmentsMap.set(item.legacyId, item)
      }
    })

    return Array.from(treatmentsMap.values())
  }, [currentPlan, selectedPlan])

  // Helper function to get treatment quantity by ID
  const getTreatmentQuantity = (
    treatments?: Treatment[],
    legacyId?: number
  ) => {
    if (!treatments || !legacyId) return '-'

    const treatment = treatments.find((t: Treatment) => t.legacyId === legacyId)

    if (!treatment) return '-'

    return treatment.quantity || '-'
  }

  // Get usage count for a treatment
  const getTreatmentUsage = (treatmentId?: string) => {
    if (!treatmentId) return '-'

    const treatments = subscriptionData?.subscription?.treatments || []

    const treatment = treatments.find(t => t.treatment?.id === treatmentId)

    if (!treatment || !treatment.usages) return 0

    return treatment.usages.length
  }

  // normalizePerkName
  const normalizePerkName = (name?: string) => {
    if (!name) return ''

    return name.toLowerCase().replace(/ /g, '')
  }

  // Get usage count for a perk
  const getPerkUsage = (name?: string) => {
    if (!name) return 0

    const perks = subscriptionData?.subscription?.perks || []

    const perk = perks.find(
      p => normalizePerkName(p.perk?.name) === normalizePerkName(name)
    )

    if (!perk || !perk.usages) return 0

    return perk.usages.length
  }

  // Utility function to sort treatments by quantity (highest first)
  const sortTreatmentsByQuantity = (
    treatments: Treatment[],
    treatmentsWithQuantity: Treatment[]
  ) => {
    return [...treatments].sort((a, b) => {
      const aTreatment = treatmentsWithQuantity.find(
        t => t.legacyId === a.legacyId
      )
      const bTreatment = treatmentsWithQuantity.find(
        t => t.legacyId === b.legacyId
      )

      const aQuantity = aTreatment?.quantity ? Number(aTreatment.quantity) : 0
      const bQuantity = bTreatment?.quantity ? Number(bTreatment.quantity) : 0

      return bQuantity - aQuantity // Sort descending by quantity
    })
  }

  // Get ordered treatments based on requirements
  const getOrderedTreatments = () => {
    if (!currentPlan || !selectedPlan || allTreatments.length === 0) return []

    // Get current plan treatment IDs
    const currentPlanTreatmentIds = (currentPlan.treatments || []).map(
      (t: Treatment) => t.legacyId
    )

    // Get selected plan treatment IDs that don't exist in current plan
    const selectedPlanOnlyTreatmentIds = (selectedPlan.treatments || [])
      .filter((t: Treatment) => !currentPlanTreatmentIds.includes(t.legacyId))
      .map((t: Treatment) => t.legacyId)

    // Get current plan treatments with their quantities
    const currentPlanTreatmentsWithQuantity = currentPlan.treatments || []

    // Get selected plan treatments with their quantities
    const selectedPlanTreatmentsWithQuantity = selectedPlan.treatments || []

    // Filter and sort current plan treatments by quantity (highest first)
    const currentPlanTreatments = sortTreatmentsByQuantity(
      allTreatments.filter(t => currentPlanTreatmentIds.includes(t.legacyId)),
      currentPlanTreatmentsWithQuantity
    )

    // Get selected plan only treatments and sort by quantity
    const selectedPlanOnlyTreatments = sortTreatmentsByQuantity(
      allTreatments.filter(t =>
        selectedPlanOnlyTreatmentIds.includes(t.legacyId)
      ),
      selectedPlanTreatmentsWithQuantity
    )

    // Combine both arrays
    return [...currentPlanTreatments, ...selectedPlanOnlyTreatments]
  }

  // Get all unique perks from both plans
  const getAllUniquePerks = () => {
    const currentPlanPerks = currentPlan?.perks || []
    const selectedPlanPerks = selectedPlan?.perks || []

    // Combine perks from both plans
    const allPerks = [...currentPlanPerks, ...selectedPlanPerks]

    // Create a map to deduplicate perks by name
    const uniquePerksMap = new Map()
    allPerks.forEach(perk => {
      if (!uniquePerksMap.has(normalizePerkName(perk.name))) {
        uniquePerksMap.set(normalizePerkName(perk.name), perk)
      }
    })

    // Convert map values back to array
    return Array.from(uniquePerksMap.values())
  }

  // Check if a plan has a specific perk by name
  const hasPerk = (perkName: string, planPerks?: Perk[]) => {
    const perks = planPerks || []
    return perks.some(
      perk => normalizePerkName(perk.name) === normalizePerkName(perkName)
    )
  }

  if (!currentPlan || !selectedPlan) return null

  const orderedTreatments = getOrderedTreatments()
  const uniquePerks = getAllUniquePerks()

  return (
    <div className="bg-tabeo-primary-6/10 mt-4 overflow-hidden rounded-lg border border-tabeo-sky-1">
      <div className="subheading grid grid-cols-[232px_1fr_1fr_1fr] bg-tabeo-blue-6 text-right text-tabeo-blue-1">
        <Cell className="text-left">{t('ANNUAL MEMBER BENEFITS')}</Cell>
        <Cell>{currentPlan.name || t('CURRENT PLAN')}</Cell>
        <Cell>{selectedPlan?.name || t('NEW PLAN')}</Cell>
        <Cell>{t('CLAIMS')}</Cell>
      </div>

      {/* Treatment rows */}
      {orderedTreatments.map((treatment: Treatment) => (
        <div
          key={treatment.legacyId}
          className="grid grid-cols-[232px_1fr_1fr_1fr] border-t border-tabeo-sky-1 bg-white text-right text-sm text-tabeo-ink-0 odd:bg-tabeo-sky-2"
        >
          <Cell className="text-left">{treatment.name}</Cell>
          <Cell>
            {getTreatmentQuantity(currentPlan?.treatments, treatment.legacyId)}
          </Cell>
          <Cell>
            {getTreatmentQuantity(selectedPlan?.treatments, treatment.legacyId)}
          </Cell>
          <Cell>{getTreatmentUsage(treatment.id)}</Cell>
        </div>
      ))}

      {/* Perk rows */}
      {uniquePerks.map((perk: Perk) => (
        <div
          key={perk.name}
          className="grid grid-cols-[232px_1fr_1fr_1fr] border-t border-tabeo-sky-1 bg-white text-right text-sm text-tabeo-ink-0 odd:bg-tabeo-sky-2"
        >
          <Cell className="text-left">{perk.name}</Cell>
          <Cell className="flex justify-end p-3">
            {hasPerk(perk.name, currentPlan?.perks) ? (
              <CheckIcon className="h-4 w-4" />
            ) : (
              '-'
            )}
          </Cell>
          <Cell className="flex justify-end p-3">
            {hasPerk(perk.name, selectedPlan?.perks) ? (
              <CheckIcon className="h-4 w-4" />
            ) : (
              '-'
            )}
          </Cell>
          <Cell>{getPerkUsage(perk.name)}</Cell>
        </div>
      ))}

      {/* Monthly fee row */}
      <div className="border-tabeo-ink-5/30 grid grid-cols-[232px_1fr_1fr_1fr] border-t bg-white text-right text-sm font-medium text-tabeo-ink-0 odd:bg-tabeo-sky-2">
        <Cell className="text-left">{t('Monthly fee')}</Cell>
        <Cell>{format(currentPlan.price)}</Cell>
        <Cell>{format(selectedPlan?.price)}</Cell>
        <Cell />
      </div>
    </div>
  )
}

function Cell({
  children,
  className,
}: {
  children?: React.ReactNode
  className?: string
}) {
  return <div className={twMerge('p-3', className)}>{children}</div>
}

export default ComparisonTable
