import { Decorator } from 'final-form'

export function scrollToError() {
  document.querySelector('[data-error]')?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })
}

export const scrollToErrorDecorator: Decorator<any, any> = form => {
  const { submit } = form

  form.submit = async (...args) => {
    scrollToError()

    const result = await submit(...args)

    const { submitFailed } = form.getState()

    if (submitFailed) {
      scrollToError()
    }

    return result
  }

  return () => {
    form.submit = submit
  }
}
