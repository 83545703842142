import { plansorchestrator } from '@tabeo/platform-api-client'
import {
  Button,
  DialogBody,
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  FFNativeSelect,
} from '@tabeo/scarf2'
import { required } from '@tabeo/sharpei/utils/validations'
import FormError from 'components/Form/FormError'
import { Field } from 'components/nnts/form/Field'
import { useMemo } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import StepIndicator from './StepIndicator'

interface SelectPlanStepProps {
  selectedPlanId: string
  plans: plansorchestrator.ModelsSelectPlansAggregate[]
  currentPlanId?: string
  patientName?: string
  onSubmit: (values: any) => Promise<any>
}

const SelectPlanStep = ({
  selectedPlanId,
  plans,
  currentPlanId,
  patientName,
  onSubmit,
}: SelectPlanStepProps) => {
  const { t } = useTranslation()

  // Group plans by name for the dropdown
  const groupedPlansByName = useMemo(() => {
    const grouped = plans
      .filter(p => p.status !== 'closed' && p.id !== currentPlanId)
      .reduce((acc, plan) => {
        const { name } = plan
        if (!name) {
          return acc
        }

        return {
          ...acc,
          [name]: [...(acc[name] || []), plan],
        }
      }, {} as Record<string, plansorchestrator.ModelsSelectPlansAggregate[]>)

    return Object.entries(grouped ?? {}).map(([name, plans]) => ({
      name,
      plans,
    }))
  }, [plans, currentPlanId])

  return (
    <>
      <DialogHeader>
        <DialogTitle className="title">
          {t('Move {{name}} to a new plan', {
            name: patientName,
          })}
        </DialogTitle>
      </DialogHeader>
      <Form onSubmit={onSubmit} initialValues={{ planId: selectedPlanId }}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogBody className="p-5">
              <StepIndicator currentStep={1} />
              <p className="caption mb-4">
                {t(
                  'Select a new plan for this member. They will receive an email confirmation once the switch is complete.'
                )}
              </p>
              <Field
                name="planId"
                label={t('Select plan')}
                component={FFNativeSelect}
                description={t('Closed plans will not be shown')}
                validate={required}
              >
                <option value="" disabled>
                  {t('Select from the list')}
                </option>
                {groupedPlansByName.map(({ name, plans }) => (
                  <optgroup key={name} label={name}>
                    {plans.map(plan => (
                      <option key={plan.id} value={plan.id}>
                        {plan.internalReference || plan.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Field>
              <FormError />
            </DialogBody>
            <DialogFooter>
              <DialogClose asChild>
                <Button type="button">{t('Cancel')}</Button>
              </DialogClose>
              <Button variant="primary" type="submit" loading={submitting}>
                {t('Next')}
              </Button>
            </DialogFooter>
          </form>
        )}
      </Form>
    </>
  )
}

export default SelectPlanStep
