import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { format } from '@tabeo/sharpei/utils/currency'
import { useTranslation } from 'react-i18next'

interface PaymentChangeNotificationProps {
  currentPlanPrice: number
  selectedPlanPrice: number
}

const PaymentChangeNotification = ({
  currentPlanPrice,
  selectedPlanPrice,
}: PaymentChangeNotificationProps) => {
  const priceDifference = selectedPlanPrice - currentPlanPrice
  const { t } = useTranslation()

  if (priceDifference === 0) return null

  return (
    <div className="mt-4 flex items-start gap-2 rounded-lg border border-tabeo-ink-2 bg-tabeo-sky-2 p-4 text-sm text-tabeo-ink-0">
      <InformationCircleIcon className="h-5 w-5 flex-shrink-0 text-tabeo-ink-3" />
      <div>
        {priceDifference > 0
          ? t('Monthly payments will increase by {{amount}}', {
              amount: format(Math.abs(priceDifference)),
            })
          : t('Monthly payments will decrease by {{amount}}', {
              amount: format(Math.abs(priceDifference)),
            })}
      </div>
    </div>
  )
}

export default PaymentChangeNotification
