import { plansorchestrator } from '@tabeo/platform-api-client'
import { View } from '@tabeo/scarf'
import { Button, Tooltip } from '@tabeo/scarf2'
import { format } from '@tabeo/sharpei/utils/currency'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import SwapPlanModal from './SwapPlanModal'

type PlanPanelProps = {
  data?: plansorchestrator.ModelsGetSubscriptionsPlan
  subscription?: plansorchestrator.ModelsGetSubscriptionsSubscription
}

export default function PlanPanel({ data, subscription }: PlanPanelProps) {
  const { t } = useTranslation()
  const history = useHistory()

  const handlePanelClick = () => {
    history.push(`/plans/${data?.id}`)
  }

  const handleModalWrapperEvent = (
    e: React.MouseEvent | React.KeyboardEvent
  ) => {
    // Stop propagation to ensure the panel click doesn't trigger
    e.stopPropagation()
  }

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // Only stop propagation to allow the modal to open
    e.stopPropagation()
  }

  const isPlanSwapEnabled = subscription?.status === 'active'

  const isWithinSevenDays =
    !!subscription?.nextPaymentDate &&
    moment(subscription?.nextPaymentDate).isBefore(moment().add(7, 'days'))

  return (
    <View
      boxShadow={1}
      borderRadius={1}
      border={1}
      overflow="hidden"
      bg="white"
      onClick={handlePanelClick}
      style={{ cursor: 'pointer' }}
      data-testid="plan-panel"
    >
      <div className="flex items-center gap-5 p-5">
        {data?.imageUrl && (
          <div className="aspect-square w-20">
            <img src={data?.imageUrl} className="h-full w-full object-cover" />
          </div>
        )}
        <div className="space-y-1">
          <h2 className="heading font-normal text-tabeo-primary-3">
            {data?.name}{' '}
            {data?.internalReference ? ` / ${data?.internalReference}` : ''}
          </h2>
          <p className="text-base text-tabeo-ink-0">
            {t('{{amount}} a month', { amount: format(data?.price) })}
          </p>
        </div>
        {isPlanSwapEnabled && !isWithinSevenDays && (
          <span
            onClick={handleModalWrapperEvent}
            onKeyDown={handleModalWrapperEvent}
            role="presentation"
            className="ml-auto"
          >
            <SwapPlanModal>
              <Button
                type="button"
                variant="secondary"
                size="sm"
                onClick={handleButtonClick}
              >
                {t('Change plan')}
              </Button>
            </SwapPlanModal>
          </span>
        )}
        {isWithinSevenDays && (
          <Tooltip
            placement="top"
            content={p => (
              <>
                <Tooltip.Title {...p.popper}>
                  <Tooltip.Arrow {...p.arrow} />
                  {t(
                    'Plan changes are blocked within 7 days of the next payment.'
                  )}
                </Tooltip.Title>
              </>
            )}
            wrapperClassName="ml-auto"
          >
            <Tooltip.Arrow />
            <Button variant="secondary" size="sm" disabled>
              {t('Change plan')}
            </Button>
          </Tooltip>
        )}
      </div>
    </View>
  )
}
