import { useTranslation } from 'react-i18next'

interface StepIndicatorProps {
  currentStep: number
}

const StepIndicator = ({ currentStep }: StepIndicatorProps) => {
  const { t } = useTranslation()

  return (
    <div className="mb-5">
      <div className="h-1 flex-1 overflow-hidden rounded-full bg-tabeo-primary-6">
        <div
          className="h-full bg-tabeo-primary-3 transition-all duration-300"
          style={{ width: `${(currentStep / 2) * 100}%` }}
        />
      </div>
      <div className="mt-2 text-xs text-tabeo-ink-3">
        {t('STEP {{current}}/2', { current: currentStep })}
      </div>
    </div>
  )
}

export default StepIndicator
