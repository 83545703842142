import { Breadcrumb } from '@tabeo/scarf'
import LayoutSideNavbar from 'components/layouts/LayoutSideNavbar'
import SubPage from 'components/layouts/Subpage'
import TwoColumns, { Column } from 'components/layouts/TwoColumns'
import DatoNotifications from 'components/Notifications/DatoNotifications'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'
import ActionPanel from './ActionPanel'
import BenefitsPanel from './BenefitsPanel'
import ClinicianPanel from './ClinicianPanel'
import Documents from './Documents'
import Header from './Header'
import PatientPanel from './PatientPanel'
import PaymentMethodPanel from './PaymentMethodPanel'
import PlanPanel from './PlanPanel'
import Progressbar from './Progressbar'

export default function SubscriptionV2() {
  const { t } = useTranslation()
  const { data } = useSubscriptionV2()

  return (
    <LayoutSideNavbar isLoading={!data}>
      {data && (
        <SubPage
          renderBreadcrumb={props => (
            <Breadcrumb {...props} mb={4}>
              <Breadcrumb.Item as={Link} to="/plans">
                {t('Plans')}
              </Breadcrumb.Item>
              <Breadcrumb.Item
                as={Link}
                to={`/subscriptions/${data?.subscription?.id}`}
              >
                {data?.subscription?.displayId}
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
        >
          <DatoNotifications />
          <Header data={data.subscription} />
          <TwoColumns mt={4}>
            <Column>
              <Progressbar data={data.subscription} />
              <ActionPanel data={data.subscription} />
              <PlanPanel data={data.plan} subscription={data.subscription} />
              <BenefitsPanel data={data.subscription} />
            </Column>
            <Column>
              <PatientPanel data={data} />
              <ClinicianPanel vendorClinician={data.vendorClinician} />
              <PaymentMethodPanel data={data} />
              <Documents data={data} />
            </Column>
          </TwoColumns>
        </SubPage>
      )}
    </LayoutSideNavbar>
  )
}
